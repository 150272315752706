import React, { useState, useEffect, memo, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
  ConfigWrapper,
  CustomButtonGrp,
  FileItem,
  OneDriveWrapper,
  PopupBody,
  PreviewWrapper,
  Thumbnail,
} from './styles'
import {
  FileProps,
  File,
  FileName,
  FileDesc,
  Row,
  FileList,
  FileActions,
} from '../File/components'
import ProgressBar from '../../controls/ProgressBar'
import { formatFileSize } from '../../utils/helper'
import ActionConfigGenerator from './ActionConfigGenerator'
import { useJobConfig } from '../JobConfig/JobContext'
import FileIcon from '../File/FileIcon'
import jobConfig from '../../configuration/jobConfig'
import Button from '../../controls/Button'
import trashIcon from '../../controls/icons/trash'
import { deleteNotification } from '../../store/notification/action'
import PdfPasswordRequired from '../../components/File/PdfPasswordRequired'
import FileSkeletonLoader from '../File/FileListSkeletonLoader'
import SaveToOneDrive from '../../controls/SaveToOneDrive'
import Checkbox from '../../controls/Checkbox'
import allPagesIcon from '../../controls/icons/allPages'
import selectPagesIcon from '../../controls/icons/selectPages'
import ButtonGroup from '../../controls/ButtonGroup'
import FormRow from '../../controls/FormRow'
import InputField from '../../controls/InputField'
import InputFormRow from '../../controls/InputFormRow'
import { ButtonRoundCorner } from '../../controls/Common'
import { useTranslation } from '../../i18n/useTranslation'

const RenderItem = memo((props) => {
  const t = useTranslation()
  const { file, onDeleteFile, onCancelUpload, i, isShowDelete } = props
  if (file.status === 'error') {
    switch (file.error) {
      // case 'UploadFailed':
      //   return <FileUploadError {...file} onDeleteFile={onDeleteFile} />
      case 'PasswordRequired':
        return (
          <PdfPasswordRequired
            {...file}
            isShowDelete={isShowDelete}
            onDeleteFile={onDeleteFile}
          />
        )
      default:
        return
    }
  }
  return (
    <FileItem>
      <File key={file?.docIdExt + i}>
        <Thumbnail>
          <FileIcon
            name={file?.fileName}
            thumbnail={file?.docMetadata?.thumbnail}
            inProgress={file?.status !== 'finished'}
          />
        </Thumbnail>
        <FileDesc>
          <FileName>{(file && file?.fileName) || ''}</FileName>
          {file?.status !== 'finished' && (
            <ProgressBar progress={file?.progress} />
          )}
          {file?.status === 'finished' && (
            <Row>
              <FileProps>
                <span>
                  {t('lblSize')}: {formatFileSize(file?.docMetadata?.size)}
                </span>
                <span>
                  {t('lblPageCount')}: {file?.docMetadata?.pageCount}
                </span>
              </FileProps>
              {isShowDelete && (
                <FileActions>
                  <Button
                    type="button"
                    size="small"
                    startIcon={trashIcon}
                    onClick={onDeleteFile}
                  >
                    {t('btnDelete')}
                  </Button>
                </FileActions>
              )}
            </Row>
          )}
        </FileDesc>
      </File>
    </FileItem>
  )
})
const StartJobPopup = () => {
  const {
    tool,
    actionConfig,
    docIdExt,
    updateActionConfig,
    fileUploadOpen,
    selectedRows,
  } = useJobConfig()
  const t = useTranslation('tool_list')

  const dispatch = useDispatch()
  const saveToOneDriveRef = useRef()
  const isReadWrite = useSelector((state) => state.teamsAuth.isReadWrite)
  const [isPopupOpen, setIsPopupOpen] = useState(false)

  const inputFiles = useSelector((state) =>
    state.notification.filter(
      (e) => !e?.isNotification && e.toolName === tool?.toolName
    )
  )
  const [isPristine, setIsPristine] = useState(true)
  const _inputFiles = Array.isArray(inputFiles) ? inputFiles : [inputFiles]

  const filesReady = useMemo(
    () => _inputFiles[0]?.status === 'finished',
    [_inputFiles]
  )
  useEffect(() => {
    const configs =
      tool && jobConfig[tool.toolName] && jobConfig[tool.toolName].jobConfig
        ? jobConfig[tool.toolName].jobConfig
        : {}

    let _config = null
    Object.keys(configs).map((value, index, array) => {
      console.log('value, index, array', value, index, array)
      _config = configs[value]
    })
    updateActionConfig({ ..._config })
    // setActionConfig(_config)
  }, [])

  const onChange = (e) => {
    let _config = { ...actionConfig }
    if (e?.target?.name) {
      if (e.target.name === 'ocr')
        _config = { ..._config, [e.target.name]: e.target.checked }
      else if (e.target.type === 'checkbox') {
        _config = { ..._config, [e.target.name]: e.target.checked }
      } else _config = { ..._config, [e.target.name]: e.target.value }
    }

    // setActionConfig({ ..._config })
    updateActionConfig(_config)
    setIsPristine(false)
  }
  useEffect(() => {
    if (filesReady) {
      const event = {
        target: {
          name: 'customItemPath',
          value: selectedRows[0]?.path,
        },
      }
      onChange(event)
    }
  }, [filesReady])
  const handleConsentPopupClick = () => {
    if (saveToOneDriveRef.current) {
      saveToOneDriveRef.current.consentPopupClose()
    }
  }

  const locationHandler = (itemPath) => {
    // itemPath.push(inputFiles[0]?.fileName)
    const event = {
      target: {
        name: 'customItemPath',
        value: itemPath.join('/'),
      },
    }
    onChange(event)
    setIsPopupOpen(false)
  }

  return (
    <SaveToOneDrive
      ref={saveToOneDriveRef}
      showPopup={isPopupOpen}
      closePopup={() => setIsPopupOpen(false)}
      title="Select Location"
      btnPrimaryAction={locationHandler}
      // btnPrimaryError={() => setLoading(false)}
      btnPrimaryTitle="OK"
    >
      <PopupBody>
        <PreviewWrapper>
          <FileList>
            {!_inputFiles || !_inputFiles.length ? (
              <FileSkeletonLoader />
            ) : (
              _inputFiles.map((file, i) => (
                <RenderItem
                  file={file}
                  i={i}
                  onDeleteFile={() =>
                    dispatch(deleteNotification(file.docIdExt))
                  }
                  isShowDelete={_inputFiles.length > 1}
                />
              ))
            )}
          </FileList>

          <ConfigWrapper>
            {/* <AddFilesWrapper>
            <AddFilesInfo>
              <strong>{maxFiles - (inputFiles.length || 0)}</strong>{' '}
              {t('addFileInfo1')} <strong>{maxFiles}</strong>{' '}
              {t('addFileInfo2')}
            </AddFilesInfo>
            <Root className="btn btn--secondary" endIcon={AngleDownIcon}>
              <SelectFileSource
                alignDropdown="right"
                label={t('btnAddFile')}
                color="secondary"
              />
            </Root>
          </AddFilesWrapper> */}

            <ActionConfigGenerator
              toolName={tool.toolName}
              inputFiles={_inputFiles}
              form={actionConfig}
              disabled={false}
              isPristine={isPristine}
              onChange={onChange}
            />
            {isReadWrite ? (
              <Checkbox
                id="saveToOneDrive"
                name="isSaveToOneDrive"
                onChange={onChange}
                checked={actionConfig?.isSaveToOneDrive}
                disabled={!filesReady}
              >
                {t('btnSaveToOneDrive')}
              </Checkbox>
            ) : (
              <ButtonRoundCorner
                color={'secondary'}
                onClick={handleConsentPopupClick}
              >
                {t('btnGrantOneDriveAccess')}
              </ButtonRoundCorner>
            )}

            {actionConfig?.isSaveToOneDrive && (
              <OneDriveWrapper>
                <label className="formRow__label">
                  {t('lblOneDriveDirectory')}
                </label>

                <InputField
                  type="text"
                  disabled
                  value={'OneDrive/' + (actionConfig?.customItemPath || '')}
                />
                <FormRow>
                  <CustomButtonGrp
                    name="pageLocationType"
                    items={[
                      {
                        id: 'btnSelectPages',
                        value: 'custom',
                        label: t('lblCustomDirectory'),
                        icon: selectPagesIcon,
                        disabled: !filesReady,
                      },
                    ]}
                    type="button"
                    value={'custom'}
                    onClick={(_, e) => {
                      e.preventDefault()
                      if (_.target.value === 'custom') {
                        setIsPopupOpen(true)
                      }
                    }}
                    // onChange={onChange}
                  />
                </FormRow>
              </OneDriveWrapper>
            )}
          </ConfigWrapper>
        </PreviewWrapper>
      </PopupBody>
    </SaveToOneDrive>
  )
}

export default StartJobPopup
