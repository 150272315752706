import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getFileContent } from '../../service/oneDrive/getFileContent'
import { errorHandler } from '../error/action'
import JobHistory from '../../container/JobHistory'
import { LoadingStates } from '../../utils/enums'
import { jobHistoryOverviewThunk } from './jobHistoryAsyncThunk'
const initialState = {
  values: [],
  currentPage: 1,
  totalPages: 49,
  totalItems: 489,

  loading: LoadingStates.IDLE,
}
const fetchJobHistoryOverview = createSlice({
  name: 'drive',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(jobHistoryOverviewThunk.pending, (state, action) => {
      state.loading = LoadingStates.PENDING
    })
    builder.addCase(jobHistoryOverviewThunk.fulfilled, (state, action) => {
      state.loading = LoadingStates.FULFILLED
      state.values = action.payload.values
      state.currentPage = action.payload.currentPage
      state.totalPages = action.payload.totalPages
      state.totalItems = action.payload.totalItems
    })
    builder.addCase(jobHistoryOverviewThunk.rejected, (state, action) => {
      state.loading = LoadingStates.REJECTED
    })
  },
})
export default fetchJobHistoryOverview.reducer
