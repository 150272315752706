import React, { useEffect, useMemo, useState } from 'react'

import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import pdfFileIcon from '../../../../controls/icons/pdfFile'
import globeIcon from '../../../../controls/icons/globe'
import compressIcon from '../../../../controls/icons/compress'
import Checkbox from '../../../../controls/Checkbox'

import { useJobConfig } from '../../../JobConfig/JobContext'
import { isMimeTypeSupported } from '../../../../utils/mimeTypes'
import { useTranslation } from '../../../../i18n/useTranslation'

const MergePdfConfig = ({ form, onChange, inputFiles }) => {
  const { updateActionConfig } = useJobConfig()
  const t = useTranslation('tool_mergePdf')
  const [showOcrOption, setShowOcrOption] = useState(false)
  const hasImageFiles = useMemo(
    () =>
      (inputFiles &&
        inputFiles.filter((f) => isMimeTypeSupported(f?.name, 'images'))
          .length) ||
      0,
    [inputFiles]
  )
  const fileSize = useMemo(() => inputFiles && inputFiles.length, [inputFiles])
  useEffect(() => {
    if (hasImageFiles > 0) {
      setShowOcrOption(hasImageFiles > 0)
    } else setShowOcrOption(false)
  }, [hasImageFiles])
  useEffect(() => {
    if (fileSize)
      updateActionConfig({
        fileSize: fileSize || 0,
      })
  }, [fileSize])
  useEffect(() => {
    updateActionConfig({
      optimize: '',
      merge: true,
      ocr: false,
    })
  }, [])
  return (
    <>
      <FormSection>
        <FormRow label={t('lblOptimize')}>
          <ButtonGroup
            name="optimize"
            items={[
              {
                id: 'btnOptimizeNone',
                value: '',
                label: t('optimizeNone'),
                icon: pdfFileIcon,
              },
              {
                id: 'btnOptimizeWeb',
                value: 'web',
                label: t('optimizeWeb'),
                icon: globeIcon,
              },
              {
                id: 'btnOptimizeSize',
                value: 'max',
                label: t('optimizeSize'),
                icon: compressIcon,
              },
            ]}
            type="button"
            value={form.optimize}
            onChange={onChange}
          />
        </FormRow>
        <p className="action-description">
          {t(`optimizeDesc_${form.optimize}`)}
        </p>

        {showOcrOption && (
          <FormRow>
            <Checkbox
              id="chkOcr"
              name="ocr"
              onChange={onChange}
              checked={form.ocr}
            >
              {t('lblOcr')}
            </Checkbox>
          </FormRow>
        )}
      </FormSection>

      {inputFiles.length < 2 && (
        <p className="action-description">{t(`twoOrMoreFileRequired`)}</p>
      )}
    </>
  )
}

export default MergePdfConfig
