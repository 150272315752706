import React, { useContext, useEffect, useState } from 'react'
import { makeStyles, CounterBadge } from '@fluentui/react-components'
import {
  Outlet,
  Link,
  useLocation,
  redirect,
  useNavigate,
} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { HomeRegular } from '@fluentui/react-icons'
import styled from 'styled-components'

import { HeaderActiveLink } from '../utils/enums'
import Notification from '../components/Notification'
import ButtonRow from '../controls/ButtonRow'
import { login, logout } from '../store/auth/action'
import Icons from '../assets/Icons'
import { useTranslation } from '../i18n/useTranslation'

const useStyles = makeStyles({
  icon: {
    height: '20px',
    width: '20px',
  },
})
const CustomIcon = styled(Icons)`
  position: relative;
  width: 20px;
  height: 20px;
  cursor: pointer;
`

const Header = () => {
  const styles = useStyles()
  const location = useLocation()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const t = useTranslation()

  const pdf4meOauth = async () => {
    if (!isAuthenticated) {
      dispatch(login())
    } else {
      dispatch(logout())
    }
  }

  const url = 'https://teamsappapi.pdf4me-dev.net/'
  return (
    <div className="main_wrapper">
      <div className="main">
        <nav className="header">
          <Link to={'/home'} className="btn nav_tool nav_icon_tool">
            <HomeRegular className={styles.icon} />
          </Link>
          <div className="vertical_line" />
          <div className="btn nav_tool nav_icon_tool">
            {HeaderActiveLink[location.pathname]
              ? HeaderActiveLink[location.pathname]
              : ''}
          </div>
          <div className="spacer" />
          <ButtonRow noWrap>
            {/* <button
              onClick={testUserInfo}
              className="btn nav_tool nav_icon_tool"
            >
              TestUserInfo
            </button> */}

            <CustomIcon
              toolName="jobHistory"
              onClick={() => navigate('job-history')}
            />
            <Notification />
            {/* <button onClick={loginTest} className="btn nav_tool nav_icon_tool">
              Cookie
            </button> */}
            <button
              onClick={pdf4meOauth}
              className="btn nav_tool nav_icon_tool"
            >
              {isAuthenticated ? t('btnLogout') : t('login')}
            </button>
          </ButtonRow>
        </nav>
        <Outlet />
      </div>
    </div>
  )
}

export default Header
