import React from 'react'
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Navigate,
} from 'react-router-dom'
import * as microsoftTeams from '@microsoft/teams-js'
import { useSelector } from 'react-redux'

// import Privacy from '../components/Privacy'
// import TermsOfUse from '../components/TermsOfUse'
// import Tab from '../components/Tab'
import Home from '../container/Home'
import Login from '../container/Login'
import PdfViewer from '../container/PdfViewer/edit'
import SignPdfViewer from '../container/PdfViewer/sign'
import Header from '../layout/Header'
import Document from '../container/Document'
import PdfPreview from '../components/PdfPreview'
import PageNotFound from '../container/PageNotFound'
import OrganizePdf from '../container/OrganizePdf'
import TeamsAuthorize from '../container/TeamsAuthorize'
import ProtectedRoutes from './ProtectedRoutes'
import JobHistory from '../container/JobHistory'

const Router = () => {
  const { isReadFiles } = useSelector((state) => state.teamsAuth)
  microsoftTeams.app
    .initialize()
    .then(() => {
      microsoftTeams.app.notifyAppLoaded()
      microsoftTeams.app.notifySuccess()
    })
    .catch((error) => console.error(error))

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route>
        <Route path="/">
          {/* Public Route */}
          <Route path="need-consent" element={<TeamsAuthorize />} />
          {/* Protected Route */}
          {/* <Route element={<ProtectedRoutes isReadFiles={isReadFiles} />}> */}
          <Route element={<Header />}>
            <Route index path="home" element={<Home />} />
            <Route path="document" element={<Document />} />
            <Route path="login" element={<Login />} />
            <Route path="job-history" element={<JobHistory />} />
            <Route path="viewer" element={<PdfViewer />} />
            <Route path="sign-viewer" element={<SignPdfViewer />} />
            <Route path="preview" element={<PdfPreview />} />
            <Route path="OrganizePdf" element={<OrganizePdf />} />
          </Route>
          {/* </Route> */}

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
        {/* <Route path="*" element={<Navigate to={'/404'} />} /> */}
        <Route path="/404" element={<PageNotFound />} />
      </Route>
    )
  )
  return <RouterProvider router={router} />
}

export default Router
