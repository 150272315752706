import { api } from '../initFetch'

export const createDocumentLink = async (
  docBlobRefs,
  language,
  toolName,
  fileStorageId
) => {
  const res = await api.post('/p4m/Document/CreateDocumentLink', {
    type: 'download',
    docBlobRefs,
    language,
    duration: 336,
    toolName,
    fileStorageId,
  })
  return res?.json
}
