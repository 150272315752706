import { app } from '@microsoft/teams-js'
import { locales } from './enums'

export const getTeamsLanguage = async () => {
  try {
    const context = await app.getContext()
    const lang = context.app.locale
    if (locales.includes(lang.split('-')[0]?.toString()))
      return lang.split('-')[0]?.toString()
    return 'en'
  } catch (error) {
    console.error('Failed to fetch Teams context:', error)
    return 'en'
  }
}
