import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import {
  FluentProvider,
  teamsHighContrastTheme,
  Spinner,
  tokens,
} from '@fluentui/react-components'
import { useTeamsUserCredential } from '@microsoft/teamsfx-react'
import { PersistGate } from 'redux-persist/integration/react'
import styled from 'styled-components'
import { app } from '@microsoft/teams-js'

import Router from './routes/index.jsx'
import { TeamsFxContext } from './components/TeamFxContextProvider'
import config from './lib/config'
import { Theme } from './theme'
import store, { persistor } from './store'
import { ViewportProvider } from './hooks/ViewportContext'
import {
  updateReadWriteField,
  updateUserInfo,
} from './store/teamsAuth/teamsAuthSlice.jsx'
import { fetchRecentDriveFiles } from './store/drive/oneDriveApiSlice.jsx'
import { browserHistory } from './history'
import LanguageProvider from './i18n/LanguageProvider.jsx'
import Icons from './assets/Icons/index.jsx'

// import { useNavigate } from 'react-router-dom'

const SpinnerWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default function App() {
  const { loading, theme, themeString, teamsUserCredential, context } =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint,
      clientId: config.MS_ClientId,
    })
  // const location = useLocation()

  useEffect(() => {
    document.documentElement.setAttribute(
      'data-theme',
      themeString === 'dark' || themeString === 'contrast' ? 'dark' : 'light'
    )
  }, [themeString])

  // const dispatch = useDispatch()
  // const navigate = useNavigate()
  const handleBeforeLift = async () => {
    await app.initialize()
    if (!teamsUserCredential) {
      throw new Error('TeamsFx SDK is not initialized.')
    }
    console.log('context', context)
    try {
      const userInfo = await teamsUserCredential.getUserInfo()
      store.dispatch(
        updateUserInfo({
          ...userInfo,
        })
      )

      const res = await store.dispatch(
        fetchRecentDriveFiles(teamsUserCredential)
      )
      if (fetchRecentDriveFiles.rejected.match(res)) {
        // browserHistory.push('/need-consent')
        await store.dispatch(
          updateUserInfo({
            isReadFiles: false,
          })
        )
        store.dispatch(updateReadWriteField({ isReadWrite: false }))
      } else if (fetchRecentDriveFiles.fulfilled.match(res)) {
        await store.dispatch(updateUserInfo({ ...userInfo, isReadFiles: true }))
        await store.dispatch(updateReadWriteField())
      }

      return browserHistory.push('/home')
    } catch (error) {
      // browserHistory.push('/need-consent')
    }
  }

  return (
    <>
      <ViewportProvider>
        <TeamsFxContext.Provider
          value={{ theme, themeString, teamsUserCredential, context }}
        >
          <FluentProvider
            theme={
              themeString === 'dark'
                ? Theme.darkTheme
                : themeString === 'contrast'
                ? teamsHighContrastTheme
                : {
                    ...Theme.lightTheme,
                    colorNeutralBackground3: '#eeeeee',
                  }
            }
            style={{ background: tokens.colorNeutralBackground3 }}
          >
            {loading ? (
              <SpinnerWrapper>
                <Icons toolName={'squareLoader'} />
              </SpinnerWrapper>
            ) : (
              <LanguageProvider>
                <Provider store={store}>
                  <PersistGate
                    loading={
                      <SpinnerWrapper>
                        {/* <Spinner
                          style={{ margin: 100 }}
                          labelPosition="below"
                          label=""
                          size="extra-large"
                        /> */}
                        <Icons toolName={'squareLoader'} />
                      </SpinnerWrapper>
                    }
                    persistor={persistor}
                    onBeforeLift={handleBeforeLift} // Runs before app renders after state rehydration
                  >
                    <Router />
                  </PersistGate>
                </Provider>
              </LanguageProvider>
            )}
          </FluentProvider>
        </TeamsFxContext.Provider>
      </ViewportProvider>
    </>
  )
}
