let currentLanguage = 'en' // Default language
let translations = {}
let compiledTranslations = {}
const sharedTranslationMap = [
  'tool_sharedTranslations',
  'page_sharedTranslations',
]
// set the language
const setLanguage = (lang) => {
  currentLanguage = lang
}
const flattenObject = (data) => {
  const flattened = {}

  Object.values(data).forEach((nestedObj) => {
    Object.entries(nestedObj).forEach(([key, value]) => {
      flattened[key] = value
    })
  })

  return flattened
}

// load the shared translation
const loadTranslationModule = (fileName, locale) => {
  if (!fileName) return
  if (Object.keys(translations[fileName] || {}).length === 0) {
    try {
      translations[
        fileName
      ] = require(`../translations/data/${fileName}.${locale}.json`)
    } catch (error) {
      console.error(`${fileName} translation for locale "${locale}" not found.`)
      translations[fileName] = {} // Fallback to an empty object
    }
  }
  return translations[fileName]
}

const loadTranslations = (moduleName) => {
  // Ensure common translations are loaded
  if (!sharedTranslationMap.includes(moduleName))
    sharedTranslationMap.push(moduleName)
  sharedTranslationMap.map(
    (e) =>
      !translations[moduleName] && loadTranslationModule(e, currentLanguage)
  )

  compiledTranslations = {
    ...compiledTranslations,
    ...flattenObject(translations),
  }
  return compiledTranslations
}

const t = (moduleName, key) => {
  const moduleTranslation = compiledTranslations[key]
    ? compiledTranslations[key]
    : `#!${key}!#`
  return moduleTranslation
}

const i18n = { setLanguage, loadTranslationModule, t, loadTranslations }

export default i18n
