import React from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import FormSection from '../../../../controls/FormSection'
import FormattedText from '../../../../controls/FormattedText'
import convertDraftIcon from '../../../../controls/icons/convertDraft'
import convertQualityIcon from '../../../../controls/icons/convertQuality'
import { useTranslation } from '../../../../i18n/useTranslation'

const PdfToWordConfig = ({ onChange, form }) => {
  const t = useTranslation('tool_pdfToWord')
  return (
    <FormSection>
      <FormRow label={t('lblQuality')}>
        <ButtonGroup
          name="quality"
          items={[
            {
              id: 'btnQualityDraft',
              value: 'draft',
              label: t('qualityDraft'),
              icon: convertDraftIcon,
            },
            {
              id: 'btnQualityHigh',
              value: 'high',
              label: t('qualityHigh'),
              icon: convertQualityIcon,
            },
          ]}
          type="button"
          value={form.quality}
          onChange={onChange}
        />
      </FormRow>
      {form.quality === 'high' ? (
        <FormattedText
          t={t}
          resKey={`md_convertFromPdfDesc_${form.quality}_isPro`}
        />
      ) : (
        <FormattedText t={t} resKey={`md_convertFromPdfDesc_${form.quality}`} />
      )}
    </FormSection>
  )
}

export default PdfToWordConfig
