import React from 'react'
import styled, { keyframes } from 'styled-components'
import { colors } from '../../theme'

const travel = keyframes`
  0% {
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dashoffset: -100;
  }
`
const Container = styled.svg`
  --uib-size: 35px;
  --uib-color: ${colors.secondary};
  --uib-speed: 1.2s;
  --uib-bg-opacity: 0.1;
  height: var(--uib-size);
  width: var(--uib-size);
  transform-origin: center;
  will-change: transform;
  overflow: visible;
`
const Car = styled.rect`
  fill: none;
  stroke: var(--uib-color);
  stroke-dasharray: 25, 75;
  stroke-dashoffset: 0;
  animation: ${travel} var(--uib-speed) linear infinite;
  will-change: stroke-dasharray, stroke-dashoffset;
  transition: stroke 0.5s ease;
`

const Travel = styled.rect`
  fill: none;
  stroke: var(--uib-color);
  opacity: var(--uib-bg-opacity);
  transition: stroke 0.5s ease;
`
export default function (props) {
  const { className } = props

  return (
    <div className={className ? className : ''}>
      <Container class="container" viewBox="0 0 35 35" height="35" width="35">
        <Travel
          class="track"
          x="2.5"
          y="2.5"
          fill="none"
          stroke-width="5px"
          width="32.5"
          height="32.5"
        />
        <Car
          class="car"
          x="2.5"
          y="2.5"
          fill="none"
          stroke-width="5px"
          width="32.5"
          height="32.5"
          pathlength="100"
        />
      </Container>
    </div>
  )
}
