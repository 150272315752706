import { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'

// import { useTranslation } from '../../hooks/TranslationContext'
import jobConfig from '../../configuration/jobConfig'
import { formatByteSize } from '../../utils/valueFormatter'
import { copyTextToClipboard } from '../../utils/copyTextToClipboard'
import { createId } from '../../utils/createId'
import ResponsiveTable from '../../controls/ResponsiveTable'
import ButtonRow from '../../controls/ButtonRow'
import Button from '../../controls/Button'
import ToolTip from '../../controls/ToolTip'
import Popup from '../../controls/Popup'
import DownloadIcon from '../../controls/icons/download'
import EditIcon from '../../controls/icons/edit'
import EmailIcon from '../../controls/icons/email'
import LinkIcon from '../../controls/icons/link'
import OkIcon from '../../controls/icons/ok'
import FolderIcon from '../../controls/icons/folder'
import { fileDownload } from '../../service/document/getDownloadUrl'
import SendDocumentByEmail from '../../components/SendDocumentByEmail'
import { device, media, layout } from '../../theme'
import { useTranslation } from '../../i18n/useTranslation'
import ToolAction from './ToolAction.jsx'
import JobConfig from '../../components/JobConfig'
import { redirectToViewer } from '../../utils/helper.jsx'
import { useDispatch } from 'react-redux'
import { downloadFile } from '../../store/notification/action.jsx'
import { useNavigate } from 'react-router-dom'
import { useLanguage } from '../../i18n/LanguageProvider.jsx'
import { createDocumentLinkThunk } from '../../store/jobHistory/jobHistoryAsyncThunk.jsx'

export const List = styled.div``

export const Actions = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: flex-end;
  ${media.down(device.mobile)`
  display: grid;
  grid-template-columns: 1fr;
  text-align:center;
  grid-gap:${layout.gap};
`}
`

const ToolButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  ${media.down(device.mobile)`
    grid-template-columns: 1fr;
  `}
`

const SelectTool = styled.span`
  ${media.down(device.mobile)`
    grid-column: '1 / span 2'
  `}
`

const FileList = (props) => {
  const { files, toolName, user, loading } = props
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { language } = useLanguage()
  const t = useTranslation('js_jobHistory')
  const [mailPopupOpen, setMailPopupOpen] = useState()
  const [openNextAction, setOpenNextAction] = useState()
  const [showNoStorageLeft, setShowNoStorageLeft] = useState(false)
  const [showFileDeleted, setShowFileDeleted] = useState()
  const [isMobile, setIsMobile] = useState(false)
  const [savingToMyDoc, setSavingToMyDoc] = useState()
  const [savedToMyDocMessage, setSavedToMyDocMessage] = useState()
  const [copyingUrl, setCopyingUrl] = useState()
  const [showLinkCopiedMessage, setShowLinkCopiedMessage] = useState()
  const [lastCopiedUrl, setLastCopiedUrl] = useState()
  const [downloadUrl, setDownloadUrl] = useState()

  const openFileDeleted = () => {
    setShowFileDeleted(true)
  }

  const closeFileDeleted = () => {
    setShowFileDeleted(false)
  }

  const getTools = (item) => {
    const tools = []
    const isPdfOutput = (item.name ? item.name : '')
      .toLowerCase()
      .match(/\.pdf$/)
      ? true
      : false
    if (!item.deleted) {
      const fileCount = 1

      if (toolName !== 'ProtectPdf' && fileCount > 0 && isPdfOutput) {
        const { defaults } = jobConfig

        Object.keys(jobConfig).forEach((tool) => {
          if (
            tool !== 'defaults' &&
            tool !== 'unlockPdf' &&
            tool !== 'workflow' &&
            tool !== toolName
          ) {
            const config = { ...defaults, ...jobConfig[tool] }

            const hasValidType = [
              'pdf',
              'all',
              'documentTypes',
              'pdfOffice',
            ].includes(config.inputFileTypes)

            const maxFiles = user?.paid
              ? config.maxInputFiles
              : config.maxFreeInputFiles

            if (
              hasValidType &&
              fileCount <= maxFiles &&
              fileCount >= config.minInputFiles
            ) {
              tools.push({ name: tool })
            }
          }
        })
      }
    }
    return tools
  }

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= device.mobile)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const rows = [
    {
      title: t('lblFileName'),
      field: 'name',
      width: '50%',
    },
    {
      title: t('lblFileSize'),
      field: 'size',
      align: 'right',
      width: '10%',
      renderItem: (item) => {
        return item.deleted ? '' : formatByteSize(item.size)
      },
    },
    {
      title: '',
      align: 'right',
      width: '40%',
      renderItem: (item) => {
        if (item.deleted) return <>{t('lblFileDeleted')}</>

        const showLinkCopied = () => {
          setShowLinkCopiedMessage(true)
          window.setTimeout(() => {
            setShowLinkCopiedMessage()
          }, 3000)
        }

        const downloadFileAction = async () => {
          setDownloadUrl(item.docBlobRef)
          try {
            await fileDownload([item])
          } catch (e) {
            if (e.response && e.response.status === 404) {
              openFileDeleted()
            }
          } finally {
            setDownloadUrl()
          }
        }

        const sendMail = () => {
          setMailPopupOpen(item.docBlobRef)
        }

        const handleCloseMailPopup = () => {
          setMailPopupOpen()
        }

        const copyLink = async () => {
          if (lastCopiedUrl) {
            copyTextToClipboard(lastCopiedUrl)
            showLinkCopied()
          } else {
            try {
              setCopyingUrl(true)
              const docLink = await dispatch(
                createDocumentLinkThunk({ item, language, toolName })
              ).unwrap()
              // setLastCopiedUrl(docLink.url)
              copyTextToClipboard(docLink.url)
              setCopyingUrl()
              showLinkCopied()
            } catch (error) {
              setCopyingUrl()
              showLinkCopied()
              return
            }
          }
        }
        const viewPdf = async () => {
          const file = await dispatch(
            downloadFile({
              name: item.name,
              docBlobRef: item.docBlobRef,
              storageType: item.storageType,
            })
          )
          if (file.error) {
            return
          }
          redirectToViewer(navigate, file.payload?.url, toolName)
          // await openFileInPdfViewer(item)
        }

        const forwardFiles = (targetToolName) => {
          const forward = {}
          const refId = createId(4)
          forward[refId] = {
            date: new Date(),
            files: [item],
            source: toolName,
            target: targetToolName,
          }
          sessionStorage.setItem('forward', JSON.stringify(forward))
          const redirectUrl = `${window.p4mr[targetToolName]}?r=${refId}`
          document.location.href = redirectUrl
        }

        const showSavedToMyDoc = () => {
          setSavedToMyDocMessage(true)
          window.setTimeout(() => {
            setSavedToMyDocMessage()
          }, 3000)
        }

        const handleSaveToMyDoc = async () => {
          setSavingToMyDoc(true)
          if (await handleSizeCheck([item])) {
            setSavingToMyDoc()
            return false
          }

          // const res = await saveToMyDoc([item])
          setSavingToMyDoc()
          showSavedToMyDoc()
        }

        const handleSizeCheck = async (files) => {
          let totalSize = 0
          for (const file of files) {
            totalSize = totalSize + file.size
          }

          const myDocStatusRes = {} //await getMyDocStatus()

          const storageLeft =
            myDocStatusRes.usageLimit - myDocStatusRes.usedSize

          if (totalSize > storageLeft) {
            handleShowNoStorageLeft()
            return true
          } else {
            return false
          }
        }

        const handleShowNoStorageLeft = () => {
          setShowNoStorageLeft(true)
        }
        const handleCloseNoStorageLeft = () => {
          setShowNoStorageLeft(false)
        }

        const isPdfOutput = (item.name ? item.name : '')
          .toLowerCase()
          .match(/\.pdf$/)
          ? true
          : false

        const tools = getTools(item)
        console.log('tools', tools)
        return (
          <>
            <Actions>
              <ToolTip content={t('toolTipDownload')}>
                <Button
                  variant="icon"
                  startIcon={DownloadIcon}
                  onClick={downloadFileAction}
                  loading={downloadUrl === item.docBlobRef}
                ></Button>
              </ToolTip>
              <ToolTip content={t('toolTipSendAsEmail')}>
                <Button
                  variant="icon"
                  startIcon={EmailIcon}
                  onClick={sendMail}
                ></Button>
              </ToolTip>
              {showLinkCopiedMessage ? (
                <ToolTip open content={t('toolTipCopyDownloadCompleted')}>
                  <Button variant="icon" startIcon={OkIcon} />
                </ToolTip>
              ) : (
                <ToolTip content={t('toolTipCopyDownloadLink')}>
                  <Button
                    variant="icon"
                    startIcon={LinkIcon}
                    onClick={copyLink}
                    loading={copyingUrl}
                  ></Button>
                </ToolTip>
              )}
              {isPdfOutput && (
                <ToolTip content={t('toolTipViewEdit')}>
                  <Button
                    variant="icon"
                    startIcon={EditIcon}
                    onClick={viewPdf}
                  ></Button>
                </ToolTip>
              )}
              {savedToMyDocMessage ? (
                <ToolTip open content={t('toolTipSaveToMyDocCompleted')}>
                  <Button variant="icon" startIcon={OkIcon} />
                </ToolTip>
              ) : (
                user?.paid && (
                  <ToolTip content={t('toolTipSaveToMyDoc')}>
                    <Button
                      variant="icon"
                      startIcon={FolderIcon}
                      onClick={handleSaveToMyDoc}
                      loading={savingToMyDoc}
                    ></Button>
                  </ToolTip>
                )
              )}
              {tools.length > 0 && (
                <SelectTool>
                  <Button
                    variant="link"
                    onClick={() => setOpenNextAction(item.docBlobRef)}
                  >
                    {t('btnSelectTool')}
                  </Button>
                </SelectTool>
              )}
              {openNextAction === item.docBlobRef && (
                <Popup
                  title={t('titleSelectTool')}
                  onClose={() => {
                    setOpenNextAction()
                  }}
                  footer={() => (
                    <ButtonRow>
                      <Button
                        onClick={() => {
                          setOpenNextAction()
                        }}
                      >
                        {t('btnCancel')}
                      </Button>
                    </ButtonRow>
                  )}
                >
                  <JobConfig
                    Form={() => (
                      <ToolAction
                        item={item}
                        toolName={toolName}
                        user={user}
                        isMobile={isMobile}
                      />
                    )}
                  />
                </Popup>
              )}
              {mailPopupOpen === item.docBlobRef && (
                <SendDocumentByEmail
                  user={user}
                  files={[{ ...item, fileName: item.name }]}
                  toolName={toolName}
                  onClose={handleCloseMailPopup}
                />
              )}
            </Actions>
            {showNoStorageLeft && (
              <NoStorageLeft t={t} onClose={handleCloseNoStorageLeft} />
            )}
          </>
        )
      },
    },
  ]
  return (
    <List>
      <ResponsiveTable rows={rows} data={files} />
      {showFileDeleted && (
        <Popup
          title={t('titleFileDeleted')}
          onClose={closeFileDeleted}
          footer={() => (
            <ButtonRow>
              <Button onClick={closeFileDeleted}>{t('btnClose')}</Button>
            </ButtonRow>
          )}
          size="sm"
        >
          {t('messageFileDeleted')}
        </Popup>
      )}
    </List>
  )
}
export default FileList

const NoStorageLeft = ({ t, onClose }) => {
  return (
    <Popup
      title={t('titleStorage')}
      onClose={onClose}
      footer={() => (
        <ButtonRow>
          <Button onClick={onClose}>{t('btnCancel')}</Button>
        </ButtonRow>
      )}
      size="sm"
    >
      {t('messageMoreThanAvailable')}
    </Popup>
  )
}
