import React from 'react'

const Icons = ({ toolName, className, onClick }) => {
  const Icon = require(`./${toolName}`).default
  const props = {
    ...(className && { className }),
    ...(onClick && { onClick }),
  }
  return <Icon {...props} />
}

export default Icons
