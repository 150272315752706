import React from 'react'
import styled from 'styled-components'
import { ButtonRoundCorner } from '../Common'
import { fontSize, lineHeight } from '../../theme/fontSize'
import { device, media } from '../../theme/responsive'
import { useTranslation } from '../../i18n/useTranslation'

const ConsentSection = styled.div`
  padding: 20px;
  background-color: #fff;
  border: 2px solid #0078d4; /* OneDrive color */
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`
const H3 = styled.h3`
  font-size: ${fontSize.h3};
  line-height: ${lineHeight.h3};
  font-weight: 700;
  ${media.down(device.mobile)`
    font-size: ${fontSize.h4};
    line-height: ${lineHeight.h4};
    font-weight: 700;
    `}
`
const P = styled.p`
  font-size: ${fontSize.lg};
  line-height: ${lineHeight.lg};
  ${media.down(device.mobile)`
    font-size: ${fontSize.sm};
    line-height: ${lineHeight.sm};
  `}
`
const ConsentButtonWrapper = styled.div``

const ConsentBanner = ({ onClick }) => {
  const t = useTranslation()
  return (
    <ConsentSection>
      <H3>{t('lblWeNeedYouConsent')}</H3>
      <P>{t('lblConsentToReadOneDriveFiles')}</P>
      <ConsentButtonWrapper>
        <ButtonRoundCorner color={'secondary'} onClick={onClick}>
          {t('btnAllowAccess')}
        </ButtonRoundCorner>
      </ConsentButtonWrapper>
    </ConsentSection>
  )
}

export default ConsentBanner
