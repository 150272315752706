import styled from 'styled-components'

import Dropdown from '../../controls/Dropdown'
import { colors, device, media, fontSize } from '../../theme'
import { useTranslation } from '../../i18n/useTranslation'

const Root = styled.div`
  position: fixed;
  top: 70px;
  right: 5px;
  border: none;
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 4px 4px;
  color: ${colors.text};
  .dropdown-toggle {
    font-size: ${fontSize.sm};
    color: ${colors.textContrast};
    svg {
      fill: ${colors.textContrast};
    }
  }
  ${media.down(device.tablet)`
    top: 58px;
  `}
`

export default ({
  selectionCount,
  onSelectAll,
  onClearSelection,
  onToggleSelection,
}) => {
  const t = useTranslation('tool_organizePages')
  return (
    <Root>
      <Dropdown
        items={[
          {
            text: t('btnClearSelection'),
            action: onClearSelection,
          },
          {
            text: t('btnSelectAll'),
            action: onSelectAll,
          },
          {
            text: t('btnInvertSelection'),
            action: onToggleSelection,
          },
        ]}
      >
        {selectionCount} {t('lblSelected')}
      </Dropdown>
    </Root>
  )
}
