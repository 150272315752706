import React, { useState, useEffect, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'

import FileUpload from '../FileUpload'
import toolData from '../../assets/data/toolList.json'
import { useMessage } from '../../controls/MessageBox'
import Popup from '../../controls/Popup'
import AuthPopup from '../../controls/AuthPopup'
import { useExecutePdfJobQuery } from '../../store/api/authSlice'
import hubActions from '../../store/hub/actions'
import {
  executeFileUpload,
  startPdfJob,
  cleanEmptyNotification,
} from '../../store/notification/action'
import { login } from '../../store/auth/action'
import { createId } from '../../utils/createId'
import { PopupScreen, MessageType, ToolName, PopupCat } from '../../utils/enums'
import { getFileFromURL, getJobConfig } from '../../utils/helper'
import { getMimeTypes } from '../../utils/mimeTypes'
import { colors, device, fontSize, media } from '../../theme'
import RenderPopup from './RenderPopup'
import { Spacer, BannerBtn } from './styles'
import { downloadFile } from '../../store/viewer/downloadApiSlice'
import { TeamsFxContext } from '../TeamFxContextProvider'

import JobContextProvider from './JobContext'
import { stopHub } from '../../store/hub/hubMiddleware'
import { useTranslation } from '../../i18n/useTranslation'
function MultiCarousel(props) {
  const filePickerRefs = useRef([])
  const { showMessage } = useMessage()
  const { Form } = props
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const t = useTranslation()

  const [toolPopup, setToolPopup] = useState(false)
  const [isExecute, setIsExecute] = useState(false)
  const [fileList, setFileList] = useState()
  const [toolObj, setToolObj] = useState({})
  const [index, setIndex] = useState()
  const [jobInfo, setJobInfo] = useState()
  const [screen, setScreen] = useState(PopupScreen.Action)
  const [docIdExt, setDocIdExt] = useState(null)
  const [mimeTypes, setMimeTypes] = useState([])
  const [fileExtensions, setFileExtensions] = useState([])
  const [maxInputFiles, setMaxInputFiles] = useState([])
  const [accept, setAccept] = useState([])
  const [isAuthPopup, setIsAuthPopup] = useState(false)
  const [actionConfig, setActionConfig] = useState({})
  const [isJobLoading, setIsJobLoading] = useState()
  const [selectedRows, setSelectedRows] = useState([])
  const [rowsSet, setRowSet] = useState(new Set([]))
  const [inputFileTypes, setInputFileTypes] = useState()
  const [tab, setTab] = useState(PopupCat.RECENT)

  const { data } = useExecutePdfJobQuery(jobInfo, { skip: !isExecute })

  const dispatch = useDispatch()

  const { connectionId, docIds } = useSelector((state) => state.hub)
  const notificationList = useSelector((state) => state.notification)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const notification = notificationList.filter(
    (e) => !e?.isNotification && e.toolName === toolObj?.toolName
  )

  useEffect(() => {
    if (toolObj && toolObj.toolName) {
      const config = getJobConfig(toolObj.toolName)
      const { mimeTypes, fileExtensions } = getMimeTypes(config.inputFileTypes)
      setInputFileTypes(config.inputFileTypes)
      setFileExtensions(fileExtensions)
      setMimeTypes(mimeTypes)
      setMaxInputFiles(config.maxInputFiles)
      setAccept(fileExtensions.map((ext) => `.${ext}`).join(','))
      // setState(prev => ({...prev,mimeTypes, fileExtensions }))
    }
  }, [toolObj.toolName])
  const updateActionConfig = (config, type) => {
    if (type === 'RESET') {
      setActionConfig({})
    }
    setActionConfig((prev) => ({ ...((prev && prev) || {}), ...config }))
  }
  const closeToolPopup = () => {
    setToolPopup(false)
    setIsAuthPopup(false)
    setScreen(PopupScreen.Action)
    updateActionConfig(null, 'RESET')
    dispatch(cleanEmptyNotification())
    setTab(PopupCat.RECENT)
    setRowSet(new Set([]))
    setSelectedRows([])
  }
  const fileUploadHandler = async (tool, files, id) => {
    try {
      const res = await dispatch(
        executeFileUpload({ tool, files, docIdExt: id })
      )

      !res.payload && closeToolPopup()
    } catch (error) {
      closeToolPopup()
    }
  }

  const executePdfJobHandler = async (tool, files) => {
    const _inputFiles = notificationList.filter(
      (e) => !e?.isNotification && e.toolName === tool?.toolName
    )

    try {
      setIsJobLoading(true)
      const jobRes = await dispatch(
        startPdfJob(_inputFiles, actionConfig, teamsUserCredential)
      )
      setIsJobLoading(false)
      closeToolPopup()
      if (jobRes && jobRes?.status === 'ok') {
        showMessage(
          "Job in progress. You'll be notified when it's done.",
          MessageType.Success
        )
      } else {
        closeToolPopup()
        showMessage(
          'Cannot create job. Status: ' + jobRes.status,
          MessageType.Error
        )
      }
    } catch (error) {
      setIsJobLoading(false)
      closeToolPopup()
      showMessage('Error in Job creation : ' + error.message, MessageType.Error)
    }
  }
  const openToolPopup = (currentScreen) => {
    setScreen(currentScreen)
    setToolPopup(true)
  }
  const openLoginConcentPopup = () => {
    setIsAuthPopup(true)
  }
  const onFileSelect = (
    tool,
    files,
    isDrop,
    currentScreen,
    index,
    redirect = true
  ) => {
    // console.log("tool", tool);
    files && setFileList([...files])
    tool && setToolObj(tool)
    index && setIndex(index)
    if (!isAuthenticated) {
      return openLoginConcentPopup()
    }

    if (isDrop) {
      const id = createId()
      setDocIdExt(id)
      fileUploadHandler(tool, files, id)

      redirect && openToolPopup(currentScreen)
    } else {
      openToolPopup(currentScreen)
    }
  }

  const openStartJobScreen = (tool) => {
    tool && setToolObj(tool)
    openToolPopup(PopupScreen.StartJob)
  }

  const footerBtnClick = (screen) => {
    try {
      if (screen === PopupScreen.Action) {
        openToolPopup(PopupScreen.StartJob)
        return
      }
      if (screen === PopupScreen.StartJob) {
        executePdfJobHandler(toolObj, fileList)

        return
      }
    } catch (error) {
      return
    }
  }
  const SignIn = () => {
    closeToolPopup()
    dispatch(login())
  }
  const handleButtonClick = () => {
    if (
      filePickerRefs.current[index] &&
      filePickerRefs.current[index].current
    ) {
      filePickerRefs.current[index].current.click()
    } else {
      openToolPopup(PopupScreen.Action)
    }
  }

  const oneDriveFileHandler = async () => {
    setIsJobLoading(true)
    const tempFileList = await Promise.all(
      selectedRows.map(async (e) => {
        const _fileUrl = await dispatch(
          downloadFile({
            teamsUserCredential,
            itemId: e.id,
            driveId: e.driveId,
          })
        )

        if (_fileUrl.error) {
          return null
        }
        let file
        try {
          file = await getFileFromURL(_fileUrl.payload, e.name)
        } catch (error) {
          return null
        }

        return onFileSelect(
          toolObj,
          [file],
          true,
          PopupScreen.StartJob,
          null,
          false
        )
      })
    )
    setIsJobLoading(false)
    const fileLIst = tempFileList.filter((e) => e !== null)
    if (fileLIst.length) openToolPopup(PopupScreen.StartJob)
  }
  const clearTableSelection = () => {
    setRowSet(new Set([]))
    setSelectedRows([])
  }
  const renderFooter = () => {
    if (screen === PopupScreen.Action)
      return tab === PopupCat.RECENT ? (
        <>
          <Spacer />
          <BannerBtn
            color="secondary"
            // className={styles.bannerBtn}
            disabled={!selectedRows.length}
            onClick={oneDriveFileHandler}
            loading={isJobLoading}
          >
            {screen}
          </BannerBtn>
          <BannerBtn
            // className={styles.bannerBtn}
            color="neutral"
            onClick={() => {
              if (!docIds.length) dispatch(stopHub())
              closeToolPopup()
            }}
          >
            {t('btnCancel')}
          </BannerBtn>
        </>
      ) : (
        <>
          <Spacer />
          <BannerBtn
            color="neutral"
            // className={styles.bannerBtn}
            onClick={() => {
              if (!docIds.length) dispatch(stopHub())
              closeToolPopup()
            }}
          >
            {t('btnCancel')}
          </BannerBtn>
        </>
      )

    if (screen === PopupScreen.StartJob)
      return (
        <>
          <Spacer />
          <BannerBtn
            color="secondary"
            // className={styles.bannerBtn}
            disabled={notification[0]?.status !== 'finished'}
            onClick={() => footerBtnClick(screen)}
            loading={isJobLoading}
          >
            {t('btnStartJob')}
          </BannerBtn>
          <BannerBtn
            color="neutral"
            // className={styles.bannerBtn}
            onClick={closeToolPopup}
          >
            {t('btnCancel')}
          </BannerBtn>
        </>
      )
    return <></>
  }
  if (filePickerRefs.current.length !== toolData.length) {
    filePickerRefs.current = Array(toolData.length)
      .fill()
      .map((_, i) => filePickerRefs.current[i] || React.createRef())
  }
  return (
    <JobContextProvider
      value={{
        accept,
        tool: toolObj,
        onFileSelect,
        actionConfig,
        docIdExt,
        closeToolPopup,
        openToolPopup,
        updateActionConfig,
        index,
        fileUploadOpen: handleButtonClick,
        selectedRows,
        setSelectedRows,
        inputFileTypes,
        filePickerRefs: filePickerRefs.current,
        rowsSet,
        setRowSet,
        clearTableSelection,
        multiple: maxInputFiles > 1,
        openStartJobScreen,
      }}
    >
      {toolPopup && (
        <Popup
          title={t(`lblTool_${toolObj?.toolName}`)}
          onClose={() => {
            if (!docIds.length) dispatch(stopHub())
            closeToolPopup()
          }}
          footer={renderFooter}
        >
          <RenderPopup
            screen={screen}
            onClose={closeToolPopup}
            footer={renderFooter}
            tab={tab}
            updateTab={setTab}
          />
        </Popup>
      )}
      {isAuthPopup && <AuthPopup onClose={closeToolPopup} SignIn={SignIn} />}

      {Form && <Form />}
    </JobContextProvider>
  )
}

export default MultiCarousel
