import React, { useState } from 'react'
import styled from 'styled-components'

import { device, media } from '../../theme'
import Button from '../../controls/Button'
import jobConfig from '../../configuration/jobConfig'
import { useTranslation } from '../../i18n/useTranslation'
import { PopupScreen, ToolName } from '../../utils/enums'
import { addFileFromJobHistory } from '../../store/notification/action'
import { useDispatch } from 'react-redux'
import { useJobConfig } from '../../components/JobConfig/JobContext'
import { getToolByName, getTools } from '../../utils/helper'

const ToolButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  ${media.down(device.mobile)`
    grid-template-columns: 1fr;
  `}
`

const ToolAction = (props) => {
  const { item, toolName, user, isMobile } = props
  const [loading, setLoading] = useState()
  const { openStartJobScreen } = useJobConfig()
  const dispatch = useDispatch()
  const t = useTranslation('js_jobHistory')
  const toolsArray = [
    ToolName.ORGANIZE_PAGES,
    ToolName.SIGN_PDF,
    ToolName.PDF_EDITOR,
    ToolName.SPLIT_PDF,
    ToolName.OPTIMIZE_FOR_WEB,
    ToolName.PREPARE_FOR_PRINT,
    ToolName.COMPRESS_PDF,
    ToolName.PDF_TO_IMAGE,
    ToolName.PROTECT_PDF,
    ToolName.REPAIR_PDF,
    ToolName.PDF_TO_WORD,
    ToolName.PDF_TO_EXCEL,
    ToolName.PDF_TO_POWERPOINT,

    ToolName.ADD_BARCODE,
    ToolName.ADD_WATERMARK,
    ToolName.PDF_METADATA,
  ]
 

  const forwardFiles = async (toolName) => {
    console.log('Tool Name', toolName)
    console.log('Items', item)
    setLoading(toolName)
    const tool = getToolByName(toolName)
    await dispatch(
      addFileFromJobHistory({ file: { ...item, jobType: toolName } })
    )
    openStartJobScreen(tool)
    setLoading()
  }

  const tools = getTools(item, toolName, user)
  return (
    <ToolButtons>
      {tools.map((f) => {
        return (
          <Button
            key={f.name}
            color="secondary"
            size={isMobile ? 'large' : ''}
            onClick={() => {
              forwardFiles(f.name)
            }}
            loading={f.name === loading}
          >
            {t(`lblTool_${f.name}`)}
          </Button>
        )
      })}
    </ToolButtons>
  )
}

export default ToolAction
