import { useState, useEffect } from 'react'
import styled from 'styled-components'

import { colors } from '../../theme'

const PaginationWrapper = styled.div`
  margin: 1rem 0;
  display: flex;
  justify-items: center;
`
const PageSizeWrapper = styled.div`
  margin-left: auto;
  label {
    display: inline-block;
    margin: 0 1rem;
  }
`

const PaginationButtonsWrapper = styled.div`
  padding: 0;

  button {
    display: inline-block;
    box-sizing: border-box;
    height: 32px;
    // width: 36px;
    min-width: 36px;
    padding: 0;
    border: 1px solid ${colors.gray};
    background-color: #fff;
    vertical-align: middle;
    cursor: pointer;
    transition: transform 0.1s;
    margin: 0 0.25rem;
    padding: 0 0.25rem;
  }

  &.loading {
    opacity: 0.5;
  }
`

const PageButton = styled.button`
  &:active {
    transform: scale(0.98);
    transition: transform 0.1s;
  }

  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
  &.current {
    background-color: ${colors.secondary};
    border-color: ${colors.secondary};
    color: #fff;
  }
`

export default (props) => {
  const {
    pageSize,
    totalPages,
    currentPage,
    loading,
    onPageSizeChanged,
    onPageChanged,
  } = props
  return (
    <PaginationWrapper>
      <PaginationButtons
        totalPages={totalPages}
        currentPage={currentPage}
        loading={loading}
        onPageChanged={onPageChanged}
      />
      <PageSizeWrapper>
        {/* <label>Page size</label> */}
        <div className="formRow__input">
          <select
            value={pageSize}
            onChange={(e) => {
              onPageSizeChanged(parseInt(e.currentTarget.value))
            }}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </PageSizeWrapper>
    </PaginationWrapper>
  )
}

const PaginationButtons = (props) => {
  const { totalPages, currentPage, loading, onPageChanged } = props
  if (totalPages < 2) {
    return null
  } else if (totalPages <= 7) {
    const pages = Array.apply(null, Array(totalPages)).map((index, i) => i + 1)
    return (
      <PaginationButtonsWrapper className={loading ? 'loading' : ''}>
        {pages.map((i) => {
          return (
            <PageButton
              className={i === currentPage ? 'current' : ''}
              key={i}
              onClick={() => {
                onPageChanged(i)
              }}
            >
              {i}
            </PageButton>
          )
        })}
      </PaginationButtonsWrapper>
    )
  } else {
    let pages = []
    let previewsPage = 0
    let nextPage = 0
    if (currentPage < 4) {
      pages = Array.apply(null, Array(4)).map((_, i) => i + 2)
      nextPage = 6
    } else if (currentPage > totalPages - 4) {
      const offset = totalPages - 4
      pages = Array.apply(null, Array(4)).map((_, i) => i + offset)
      previewsPage = offset - 1
    } else {
      nextPage = currentPage + 2
      pages = [currentPage - 1, currentPage, currentPage + 1]
      previewsPage = currentPage - 2
    }
    return (
      <PaginationButtonsWrapper className={loading ? 'loading' : ''}>
        <PageButton
          className={currentPage === 1 ? 'current' : ''}
          onClick={() => {
            onPageChanged(1)
          }}
        >
          1
        </PageButton>
        {previewsPage > 0 && (
          <PageButton
            onClick={() => {
              onPageChanged(previewsPage)
            }}
          >
            ...
          </PageButton>
        )}
        {pages.map((i) => (
          <PageButton
            className={i === currentPage ? 'current' : ''}
            key={i}
            onClick={() => {
              onPageChanged(i)
            }}
          >
            {i}
          </PageButton>
        ))}
        {nextPage > 0 && (
          <PageButton
            onClick={() => {
              onPageChanged(nextPage)
            }}
          >
            ...
          </PageButton>
        )}
        <PageButton
          className={currentPage === totalPages ? 'current' : ''}
          onClick={() => {
            onPageChanged(totalPages)
          }}
        >
          {totalPages}
        </PageButton>
      </PaginationButtonsWrapper>
    )
  }
}
