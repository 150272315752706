import React, { useState } from 'react'
import styled from 'styled-components'
import { SketchPicker } from 'react-color'

// import Button from '../../../../controls/Button'
import Icon from '../../../controls/Icon'
// import FormRow from '../../../../controls/FormRow'
import okIcon from '../../../controls/icons/ok'
import rotateLeftIcon from '../../../controls/icons/rotateLeft'
import rotateRightIcon from '../../../controls/icons/rotateRight'
import longArrowUpIcon from '../../../controls/icons/longArrowUp'
import ellipsisIcon from '../../../controls/icons/ellipsis'
import emptyIcon from '../../../controls/icons/empty'
import fontSizeDownIcon from '../../../controls/icons/fontSizeDown'
import fontSizeUpIcon from '../../../controls/icons/fontSizeUp'
import imageSizeDownIcon from '../../../controls/icons/imageSizeDown'
import imageSizeUpIcon from '../../../controls/icons/imageSizeUp'
import arrowsHIcon from '../../../controls/icons/arrowsH'
import arrowsVIcon from '../../../controls/icons/arrowsV'
import textCursorIcon from '../../../controls/icons/textCursor'
import handIcon from '../../../controls/icons/hand'

import Dropdown from './DropDown'
import { IconButton } from './IconButton'
import { ToggleButton } from './ToggleButton'
import {
  A4PageWidth,
  A4PageHeight,
  A4PageWidthCM,
  A4PageHeightCM,
} from './common'

import { colors, device, media } from '../../../theme'
import { useTranslation } from '../../../i18n/useTranslation'

const ToolBar = styled.div`
  margin-top: 12px;
  ${media.down(device.tablet)`
    margin-top: 8px;
  `};
`

const Root = styled.div`
  display: flex;
  // justify-content: center;
  align-items: center;
  margin: 0px auto;
  user-select: none;
  ${media.down(device.tablet)`
    align-items: center;
  `};
`
const HR = styled.hr`
  border-top: 1px solid ${colors.grayLight};
  margin: 0;
`

const Separator = styled.div`
  height: 30px;
  margin: 0 5px;
  width: 1px;
  align-self: center;
  background-color: ${colors.gray};
  ${media.down(device.tablet)`
    margin: 0 6px;
    height: 20px;
  `}

  ${media.down(device.mobile)`
    display: none;
  `}
`

const AlignGrid = styled.div`
  display: grid;
  grid-template-columns: 40px 40px 40px;
  grid-template-rows: 40px 40px 40px;
  grid-gap: 2px;
  height: 120px;
  width: 120px;
`

const Box = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${colors.grayLight};
  color: ${colors.grayLightContrast};
  display: flex;
  align-items: center;
  justify-content: center;
  &.red {
    background-color: ${colors.primary};
    fill: ${colors.primaryContrast};
  }
  .center-top {
    transform: rotate(0);
  }
  .left-top {
    transform: rotate(315deg);
  }
  .left-middle {
    transform: rotate(270deg);
  }
  .left-bottom {
    transform: rotate(225deg);
  }
  .center-bottom {
    transform: rotate(180deg);
  }
  .right-bottom {
    transform: rotate(135deg);
  }
  .right-middle {
    transform: rotate(90deg);
  }
  .right-top {
    transform: rotate(45deg);
  }
`

const Opacity = styled.div`
  text-align: left;
  // position: fixed;
  // background-color: white;
  // z-index: 1;
  // max-width: 100vw;
  // ${media.down(device.mobile)`
  //   width: 100vw;    
  // `}

  // ${media.up(device.mobile)`
  //   width: 480px;
  // `}
`

const Slider = styled.input`
  -webkit-appearance: none;
  width: 100%;
  height: 25px;
  background: ${colors.grayLight};
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: ${colors.primary};
    cursor: pointer;
  }

  &::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: ${colors.primary};
    cursor: pointer;
  }
`

const FlexEnd = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 120px;
`

// const ColorInput = styled.input`
//   display: none;
// `

const ModeFillIcon = styled.span`
  font-size: 18px;
  margin-right: 10px;
  font-weight: 600;
`

const ModeStrokeIcon = styled.span`
  font-size: 18px;
  margin-right: 10px;
  font-weight: 600;
  text-shadow: #000 0px 0px 1px;
  color: #fff;
`

const SketchBarcodeColor = styled.div`
  position: absolute;
  z-index: 10;
  ${media.down(device.mobile)`
right: 5px;
`}
  .sketch-picker {
    > div:first-child {
      padding-bottom: 50% !important;
    }
    input {
      height: auto;
    }
  }
`

const NumberInput = styled.input`
  border-top-width: initial;
  border-right-width: initial;
  border-left-width: initial;
  border-top-color: initial;
  border-right-color: initial;
  border-left-color: initial;
  max-width: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 6px;
  flex: 1 1 0%;
  border-style: none none solid;
  border-image: initial;
  border-bottom: 1px solid rgb(185, 185, 185);
  width: 49px;
  &.hide {
    visibility: hidden;
  }
  ${media.down(device.mobile)`
    width: 35px;
  `}
`

const Arrow = styled.div`
  width: 30px;
  height: 30px;
  // background-color: ${colors.grayLight};
  color: ${colors.grayLightContrast};
  display: flex;
  align-items: center;
  justify-content: center;

  .bottom {
    transform: rotate(0);
  }
  .right {
    transform: rotate(270deg);
  }
  .top {
    transform: rotate(180deg);
  }
  .left {
    transform: rotate(90deg);
  }
  &.disabled {
    opacity: 0.7;
  }
  &.hide {
    visibility: hidden;
  }
`

const MarginBox = ({ align, disabled, dispMargin, onChange, hide }) => {
  return (
    <>
      <Arrow className={hide ? 'hide' : ''}>
        {!disabled &&
          (align === 'top' || align === 'middle' || align === 'bottom') && (
            <Icon icon={arrowsVIcon} />
          )}
        {!disabled &&
          (align === 'center' || align === 'left' || align === 'right') && (
            <Icon icon={arrowsHIcon} />
          )}
      </Arrow>
      <NumberInput
        type="number"
        step="0.1"
        min="0"
        max="100"
        value={dispMargin}
        onChange={onChange}
        disabled={disabled}
        className={hide ? 'hide' : ''}
      />
    </>
  )
}

export const fontFamilyList = [
  {
    key: 'Arial',
    value: 'Arial',
    className: 'Arial',
    jsFont: 'arial',
  },
  {
    key: 'Impact',
    value: 'Impact',
    className: 'Impact',
    jsFont: 'impact',
  },
  // {
  //   key: 'Arial Unicode Ms',
  //   value: 'Arial Unicode Ms',
  //   className: 'ArialUnicodeMs',
  //   jsFont: 'arial unicode ms',
  // },
  {
    key: 'Verdana',
    value: 'Verdana',
    className: 'Verdana',
    jsFont: 'verdana',
  },
  {
    key: 'Courier New',
    value: 'Courier',
    className: 'Courier',
    jsFont: 'courier',
  },
  {
    key: 'comic sans ms',
    value: 'Comic',
    className: 'ComicSansMS',
    jsFont: 'comic sans ms',
  },
  {
    key: 'Times New Roman',
    value: 'Times New Roman',
    className: 'TimesNewRoman',
    jsFont: 'times-new-roman',
  },
]

export default ({
  // screenSize,
  config,
  updateConfig,
  stampType,
  disabled,
}) => {
  const t = useTranslation('tool_addWatermark')
  const [openColor, setOpenColor] = useState(false)

  const xalignList = [
    { key: 'left', value: t('left') },
    { key: 'center', value: t('center') },
    { key: 'right', value: t('right') },
  ]

  const yalignList = [
    { key: 'top', value: t('top') },
    { key: 'middle', value: t('middle') },
    { key: 'bottom', value: t('bottom') },
  ]

  const modeList = [
    { key: 'fill', value: t('modeFill'), ModeIcon: ModeFillIcon },
    {
      key: 'stroke',
      value: t('modeStroke'),
      ModeIcon: ModeStrokeIcon,
    },
  ]

  let screenSize
  const screenWidth = window.innerWidth

  if (screenWidth <= parseInt(device.mobile, 10)) {
    screenSize = 'mobile'
  } else if (screenWidth <= parseInt(device.tablet, 10)) {
    screenSize = 'tablet'
  } else {
    screenSize = 'desktop'
  }

  // let btnSize
  // if (screenSize === 'tablet') {
  //   btnSize = 'sm'
  // } else if (screenSize === 'mobile') {
  //   btnSize = 'xs'
  // }

  const {
    xalign,
    yalign,
    fontFamily,
    rotate,
    fontSize,
    stampPosition,
    fontBold,
    fontItalics,
    fontUnderline,
    width,
    height,
    fontColor,
    opacity,
    mode,
    lineWidth,
    marginXcm,
    marginYcm,
    showOnlyInPrint,
  } = config

  const setAlign = (x, y) => {
    setOpenColor(false)
    const newConfig = { ...config, xalign: x, yalign: y }
    updateConfig(newConfig)
  }

  const setFontFamily = (font) => {
    setOpenColor(false)
    const newConfig = { ...config, fontFamily: font }
    updateConfig(newConfig)
  }
  const setStampRotate = (angle) => {
    setOpenColor(false)
    const newConfig = { ...config, rotate: angle }
    updateConfig(newConfig)
  }

  const setFontSize = (size) => {
    setOpenColor(false)
    const newConfig = { ...config, fontSize: size }
    updateConfig(newConfig)
  }

  const setStampPosition = (position) => {
    setOpenColor(false)
    const newConfig = { ...config, stampPosition: position }
    updateConfig(newConfig)
  }

  const setImageSize = (h, w) => {
    setOpenColor(false)
    const newConfig = { ...config, width: w, height: h }
    updateConfig(newConfig)
  }

  const setFontBold = (bold) => {
    setOpenColor(false)
    const newConfig = { ...config, fontBold: bold }
    updateConfig(newConfig)
  }

  const setFontItalics = (italics) => {
    setOpenColor(false)
    const newConfig = { ...config, fontItalics: italics }
    updateConfig(newConfig)
  }

  const setFontUnderline = (underline) => {
    setOpenColor(false)
    const newConfig = { ...config, fontUnderline: underline }
    updateConfig(newConfig)
  }

  // const setFontColor = (color) => {
  //   const newConfig = { ...config, fontColor: color }
  //   updateConfig(newConfig)
  // }

  const setStampOpacity = (_opacity) => {
    const newConfig = { ...config, opacity: _opacity }
    updateConfig(newConfig)
  }

  const setMode = (_mode) => {
    setOpenColor(false)
    const newConfig = { ...config, mode: _mode }
    updateConfig(newConfig)
  }

  const setStrokeLineWidth = (_lineWidth) => {
    setOpenColor(false)
    const newConfig = { ...config, lineWidth: _lineWidth }
    updateConfig(newConfig)
  }

  const setFontColorOpacity = (color, _opacity) => {
    const newConfig = {
      ...config,
      fontColor: color,
      opacity: _opacity,
    }
    updateConfig(newConfig)
  }

  const setDragMode = (dragMode) => {
    setOpenColor(false)
    const newConfig = {
      ...config,
      dragMode,
    }
    updateConfig(newConfig)
  }

  const setShowOnlyInPrint = (_showOnlyInPrint) => {
    setOpenColor(false)
    const newConfig = {
      ...config,
      showOnlyInPrint: _showOnlyInPrint,
    }
    updateConfig(newConfig)
  }

  const [rotateLeftDisable, setRotateLeftDisable] = useState(false)
  const [rotateRightDisable, setRotateRightDisable] = useState(true)

  const onRotateLeft = () => {
    const _rotate = rotate + 45
    if (_rotate > 90) {
      return
    }

    if (rotateLeftDisable) setRotateLeftDisable(false)
    if (rotateRightDisable) setRotateRightDisable(false)

    if (_rotate < 360) {
      setStampRotate(_rotate)
    } else {
      setStampRotate(0)
    }

    if (_rotate >= 90) {
      if (!rotateLeftDisable) setRotateLeftDisable(true)
    }
  }

  const onRotateRight = () => {
    const _rotate = rotate - 45
    if (_rotate < 0) {
      return
    }

    if (rotateLeftDisable) setRotateLeftDisable(false)
    if (rotateRightDisable) setRotateRightDisable(false)

    if (_rotate >= 0) {
      setStampRotate(_rotate)
    } else {
      setStampRotate(315)
    }

    if (_rotate <= 0) {
      if (!rotateRightDisable) setRotateRightDisable(true)
    }
  }

  const increaseFontSize = () => {
    setFontSize(fontSize * 1.1)
  }

  const decreaseFontSize = () => {
    setFontSize(fontSize * 0.9)
  }

  const increaseSize = () => {
    const _w = width * 1.1
    const _h = height * 1.1
    setImageSize(_h, _w)
  }

  const decreaseSize = () => {
    const _w = width * 0.9
    const _h = height * 0.9
    setImageSize(_h, _w)
  }

  const setOpacity = (e) => {
    const { value } = e.target
    setStampOpacity(value)
  }

  const setLineWidth = (e) => {
    setOpenColor(false)
    const { value } = e.target
    setStrokeLineWidth(value)
  }

  const setMarginX = (xCM) => {
    setOpenColor(false)
    const _margin = (xCM / A4PageWidthCM) * A4PageWidth
    const newConfig = { ...config, marginX: _margin, marginXcm: xCM }
    updateConfig(newConfig)
  }

  const setMarginY = (yCM) => {
    setOpenColor(false)
    const _margin = (yCM / A4PageHeightCM) * A4PageHeight
    const newConfig = { ...config, marginY: _margin, marginYcm: yCM }
    updateConfig(newConfig)
  }

  const fontMenus = fontFamilyList.map((f) => {
    return {
      renderItem: () => <span style={{ fontFamily: f.jsFont }}>{f.value}</span>,
      action: () => {
        setFontFamily(f.key)
      },
    }
  })

  const alignMenus = [
    {
      renderItem: () => (
        <>
          <AlignGrid>
            {yalignList.map((y) =>
              xalignList.map((x) => (
                <Box
                  key={`${x.key}-${y.key}`}
                  className={xalign === x.key && yalign === y.key ? 'red' : ''}
                  onClick={() => {
                    setAlign(x.key, y.key)
                  }}
                  onKeyUp={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  {(x.key !== 'center' || y.key !== 'middle') && (
                    <Icon
                      icon={longArrowUpIcon}
                      className={`${x.key}-${y.key}`}
                    />
                  )}
                </Box>
              ))
            )}
          </AlignGrid>
        </>
      ),
      disabled,
    },
  ]

  const menuItems = [
    {
      action: () => {
        setStampPosition('foreground')
      },
      renderItem: () => (
        <>
          {t('btnSetForeground')}
          &nbsp;&nbsp;&nbsp;
          {stampPosition === 'foreground' ? (
            <Icon icon={okIcon} />
          ) : (
            <Icon icon={emptyIcon} />
          )}
        </>
      ),
    },
    {
      action: () => {
        setStampPosition('background')
      },
      renderItem: () => (
        <>
          {t('btnSetBackground')}
          &nbsp;&nbsp;&nbsp;
          {stampPosition === 'background' ? (
            <Icon icon={okIcon} />
          ) : (
            <Icon icon={emptyIcon} />
          )}
        </>
      ),
    },
    {
      action: () => {
        setShowOnlyInPrint(!showOnlyInPrint)
      },
      renderItem: () => (
        <>
          {/* {t('btnSetBackground')} */}
          Show Only In Print &nbsp;&nbsp;&nbsp;
          {showOnlyInPrint ? <Icon icon={okIcon} /> : <Icon icon={emptyIcon} />}
        </>
      ),
    },
    // opacity for text is provided under color change
    // {
    //   renderItem: () => (
    //     <>
    //       <Opacity>
    //         <FlexEnd>
    //           <div>{t('lblTransparency')}</div>
    //           <div>{Math.ceil(opacity * 100)}%</div>
    //         </FlexEnd>
    //         <Slider
    //           type="range"
    //           value={opacity}
    //           onChange={setOpacity}
    //           name="opacity"
    //           min="0"
    //           max="1"
    //           step="0.05"
    //         />
    //       </Opacity>
    //     </>
    //   ),
    // },
  ]

  const modeItems = modeList.map((f) => {
    const { value, ModeIcon, key } = f
    return {
      renderItem: () => (
        <>
          <ModeIcon>A</ModeIcon>
          <span>{value}</span>
        </>
      ),
      action: () => {
        setMode(key)
      },
    }
  })

  const lineWidthItems = [
    {
      renderItem: () => (
        <>
          <Opacity>
            <FlexEnd>
              <div>{t('lblLineWidth')}</div>
            </FlexEnd>
            <Slider
              type="range"
              value={lineWidth}
              onChange={setLineWidth}
              name="lineWidth"
              min="0.5"
              max="10"
              step="0.5"
            />
          </Opacity>
        </>
      ),
    },
  ]

  const onFontColorChange = ({ hex, rgb }) => {
    // const { hex, rgb } = color
    const { a } = rgb
    const _hex = hex === 'transparent' ? '#000000' : hex
    // setFontColor(_hex)
    // setStampOpacity(a)
    const _a = Math.round(a * 100) / 100
    setFontColorOpacity(_hex, _a)
  }

  // let colorPickerElem = null

  const dispFontFamily = (
    fontFamily ? fontFamilyList.find((f) => f.key === fontFamily).value : ''
  ).split(' ')[0]

  const dispMode = mode ? modeList.find((f) => f.key === mode).value : ''

  const dispXAlign = xalign
    ? xalignList.find((f) => f.key === xalign).value
    : ''

  const dispYAlign = yalign
    ? yalignList.find((f) => f.key === yalign).value
    : ''

  const dispHex =
    (fontColor === 'transparent' ? '#000000' : fontColor) +
    parseInt(opacity * 255, 10).toString(16)

  const marginHide =
    (xalign === 'center' && yalign === 'middle') || screenSize === 'mobile'

  if (mode === 'stroke' && screenSize === 'mobile') {
    menuItems.push({
      renderItem: () => (
        <>
          <Opacity>
            <FlexEnd>
              <div>{t('lblLineWidth')}</div>
              <div>{lineWidth}</div>
            </FlexEnd>
            <Slider
              type="range"
              value={lineWidth}
              onChange={setLineWidth}
              name="lineWidth"
              min="0.5"
              max="10"
              step="0.5"
            />
          </Opacity>
        </>
      ),
    })
  }

  if (stampType === 'image') {
    menuItems.push({
      renderItem: () => (
        <>
          <Opacity>
            <FlexEnd>
              <div>{t('lblTransparency')}</div>
              <div>{Math.ceil(opacity * 100)}%</div>
            </FlexEnd>
            <Slider
              type="range"
              value={opacity}
              onChange={setOpacity}
              name="opacity"
              min="0"
              max="1"
              step="0.05"
            />
          </Opacity>
        </>
      ),
    })
  }

  const textModeHtml = () => (
    <>
      <ToggleButton
        onClick={() => {
          setDragMode(false)
        }}
        pressed={!config.dragMode}
      >
        <Icon icon={textCursorIcon} />
      </ToggleButton>
      <ToggleButton
        onClick={() => {
          setDragMode(true)
        }}
        pressed={config.dragMode}
      >
        <Icon icon={handIcon} />
      </ToggleButton>
    </>
  )

  if (stampType === 'text' && screenSize === 'mobile') {
    menuItems.push({
      renderItem: textModeHtml,
    })
  }

  return (
    <>
      <ToolBar>
        {stampType === 'text' && (
          <>
            <Root>
              <Dropdown
                items={fontMenus}
                alignItems="right"
                disabled={disabled}
              >
                {dispFontFamily}
              </Dropdown>
              <Separator />
              {fontFamily !== 'Impact' && (
                <>
                  <ToggleButton
                    onClick={() => {
                      setFontBold(!fontBold)
                    }}
                    pressed={fontBold}
                  >
                    <b>B</b>
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => {
                      setFontItalics(!fontItalics)
                    }}
                    pressed={fontItalics}
                  >
                    <b>
                      <i>I</i>
                    </b>
                  </ToggleButton>
                </>
              )}
              <ToggleButton
                onClick={() => {
                  setFontUnderline(!fontUnderline)
                }}
                pressed={fontUnderline}
              >
                <u>U</u>
              </ToggleButton>
              {/* <ToggleButton
                onClick={() => {
                  setOpenColor(true)
                  colorPickerElem.click()
                }}
                pressed={openColor}
              >
                <b style={{ color: fontColor }}>
                  <u>A</u>
                </b>
              </ToggleButton>
              <ColorInput
                ref={elem => {
                  colorPickerElem = elem
                }}
                type="color"
                name="fontColor"
                value={fontColor}
                onChange={e => {
                  setOpenColor(false)
                  setFontColor(e.target.value)
                }} 
              /> */}

              <div>
                <ToggleButton
                  onClick={() => {
                    setOpenColor(!openColor)
                  }}
                  pressed={openColor}
                >
                  <b style={{ color: fontColor }}>
                    <u>A</u>
                  </b>
                </ToggleButton>

                {openColor && (
                  <SketchBarcodeColor
                    onBlur={() => {
                      setOpenColor(false)
                    }}
                  >
                    <SketchPicker
                      color={dispHex}
                      onChangeComplete={onFontColorChange}
                      width={230}
                    />
                  </SketchBarcodeColor>
                )}
              </div>
              <Dropdown items={modeItems} disabled={disabled}>
                {dispMode}
              </Dropdown>
              {mode === 'stroke' && screenSize !== 'mobile' && (
                <Dropdown items={lineWidthItems}>
                  {Number.parseFloat(lineWidth).toFixed(1)}
                </Dropdown>
              )}
              {stampType === 'text' && screenSize !== 'mobile' && (
                <>
                  <Separator />
                  {textModeHtml()}
                </>
              )}
            </Root>
            <HR />
          </>
        )}
        <Root>
          {stampType === 'text' && (
            <>
              <IconButton
                icon={fontSizeDownIcon}
                onClick={decreaseFontSize}
                disabled={disabled}
              />
              <IconButton
                icon={fontSizeUpIcon}
                onClick={increaseFontSize}
                disabled={disabled}
              />
              <Separator />
            </>
          )}
          {stampType === 'image' && (
            <>
              <IconButton
                icon={imageSizeDownIcon}
                onClick={decreaseSize}
                disabled={disabled}
              />
              <IconButton
                icon={imageSizeUpIcon}
                onClick={increaseSize}
                disabled={disabled}
              />
              <Separator />
            </>
          )}

          {stampType === 'text' && (
            <>
              <IconButton
                icon={rotateLeftIcon}
                onClick={onRotateLeft}
                disabled={disabled || rotateLeftDisable}
              />
              <IconButton
                icon={rotateRightIcon}
                onClick={onRotateRight}
                disabled={disabled || rotateRightDisable}
              />
              <Separator />
            </>
          )}

          <Dropdown items={alignMenus} disabled={disabled}>
            {dispXAlign} {dispYAlign}
          </Dropdown>
          <Separator />

          {!marginHide && (
            <>
              <MarginBox
                align={xalign}
                // disabled={xalign === 'center'}
                dispMargin={marginXcm}
                onChange={(e) => {
                  setMarginX(e.target.value)
                }}
              />

              <MarginBox
                align={yalign}
                // disabled={yalign === 'middle'}
                dispMargin={marginYcm}
                onChange={(e) => {
                  setMarginY(e.target.value)
                }}
              />
              <Separator />
            </>
          )}

          <Dropdown hideCaret items={menuItems} disabled={disabled}>
            <Icon icon={ellipsisIcon} />
          </Dropdown>
        </Root>
      </ToolBar>
    </>
  )
}
