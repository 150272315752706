import { createAsyncThunk } from '@reduxjs/toolkit'
import _ from 'lodash'
import { app } from '@microsoft/teams-js'

import { Type } from './mutation'
import { fileUpload } from '../../service/document/fileUpload'
import { executePdfJob } from '../../service/document/executePdfJob'
// import { createId } from "../../utils/createId";
import { getActionConfig } from '../../utils/helper'
import { errorHandler } from '../error/action'
import { ToolName } from '../../utils/enums'
import { getDownloadUrl } from '../../service/document/getDownloadUrl'
import { startHub } from '../hub/hubMiddleware'
import { getOneDriveInfo } from '../../service/oneDrive/getDriveInfo'
import { addFileFromJobHistoryService } from '../../service/document/addFileFromJobHistory'
import { createId } from '../../utils/createId'
import { formatDocs } from '../../utils/notificationHelper'

export const createNotification = (data) => (dispatch) => {
  dispatch({ type: Type.NOTIFICATION_TRIGGER, data: data })
}

const UpdateConfig = (toolName, jobConfig) => {
  let _actionsConfig = getActionConfig(toolName)
  let _jobConfig = {}

  switch (toolName) {
    case ToolName.COMPRESS_PDF:
      _actionsConfig[0].parameters = Object.keys(jobConfig).map((p) => ({
        name: p,
        value: jobConfig[p] === 'max' ? 'compressMax' : 'compress',
      }))
      break

    case ToolName.SPLIT_PDF:
      if (jobConfig.splitType === 'byPageNr') {
        let splitByPageNr = jobConfig.splitByPageNr.trim()
        if (splitByPageNr.indexOf(',') > 0) {
          const pageNrs = splitByPageNr
            .split(',')
            .map((x) => parseInt(x.trim(), 10))
            .sort((a, b) => a - b)
          _jobConfig.split = {
            splitSequence: pageNrs.join(','),
          }
        } else {
          _jobConfig.split = {
            splitByPageNr: splitByPageNr,
          }
        }
      } else if (jobConfig.splitType === 'recurring') {
        _jobConfig.split = {
          splitRecurring: jobConfig.splitRecurring.trim(),
        }
      } else if (jobConfig.splitType === 'byBarcode') {
        delete _jobConfig.split
        _jobConfig.splitByBarcode = {
          text: jobConfig.splitBarcodeText.trim(),
          filter: jobConfig.splitBarcodeFilterType,
          type: jobConfig.splitBarcodeType,
          splitPage: jobConfig.splitBarcodePage,
        }
      } else if (jobConfig.splitType === 'byText') {
        delete _jobConfig.split
        _jobConfig.splitByText = {
          splitText: jobConfig.splitText.trim(),
          splitPage: jobConfig.splitTextPage,
        }
      }

      _actionsConfig = Object.keys(_jobConfig).map((key) => ({
        actionType: key,
        parameters: Object.keys(_jobConfig[key]).map((p) => ({
          name: p,
          value: _jobConfig[key][p],
        })),
      }))

      break

    case ToolName.PDF_TO_WORD:
      _actionsConfig[0].parameters = Object.keys(jobConfig).map((p) => ({
        name: p,
        value: jobConfig[p],
      }))
      break
    case ToolName.PDF_TO_EXCEL:
      _actionsConfig[0].parameters = Object.keys(jobConfig).map((p) => ({
        name: p,
        value: jobConfig[p],
      }))
      break
    case ToolName.PDF_TO_POWERPOINT:
      _actionsConfig[0].parameters = Object.keys(jobConfig).map((p) => ({
        name: p,
        value: jobConfig[p],
      }))
      break
    case ToolName.PDF_TO_IMAGE: {
      const cpyJobConfig = { ...jobConfig }
      let customSelection = 'all'
      if (
        jobConfig?.customSelection &&
        Array.isArray(cpyJobConfig.customSelection)
      ) {
        customSelection = cpyJobConfig.customSelection.join(',')
        delete cpyJobConfig.customSelection
      }

      _actionsConfig[0].parameters = Object.keys(cpyJobConfig).map((p) => {
        if (p === 'pageSequence') {
          let pageSequence =
            cpyJobConfig[p] === 'all'
              ? 'all'
              : cpyJobConfig[p] === 'first'
              ? '1'
              : customSelection
          return {
            name: p,
            value: pageSequence,
          }
        }

        return {
          name: p,
          value: cpyJobConfig[p],
        }
      })
      break
    }
    case ToolName.PDF_TO_EPUB:
      {
        const cpyJobConfig = { ...jobConfig }
        if (cpyJobConfig?.coverType === 'firstPage')
          cpyJobConfig.firstPageCover = 'true'
        else cpyJobConfig.firstPageCover = 'false'
        _actionsConfig[0].parameters = Object.keys(cpyJobConfig).map((p) => {
          return {
            name: p,
            value: cpyJobConfig[p],
          }
        })
      }
      break
    case ToolName.PDF_CONVERTER:
    case ToolName.IMAGE_TO_PDF:
    case ToolName.WORD_TO_PDF:
    case ToolName.POWERPOINT_TO_PDF:
    case ToolName.EXCEL_TO_PDF:
    case ToolName.MERGE_PDF:
      {
        const cpyJobConfig = { ...jobConfig }
        let size = cpyJobConfig.fileSize || 0
        let ocr = cpyJobConfig.ocr
        delete cpyJobConfig.fileSize
        delete cpyJobConfig.ocr
        if (ocr) {
          _actionsConfig.push({
            actionType: 'convertFromPdf',
            parameters: [],
          })
        }
        if (cpyJobConfig.optimize)
          _actionsConfig.push({
            actionType: 'optimize',
            parameters: [],
          })
        if (size > 1 && cpyJobConfig.merge) {
          _actionsConfig.push({
            actionType: 'merge',
            parameters: [],
          })
        }
        delete cpyJobConfig.merge
        if (ocr) {
          cpyJobConfig.outputFormat = 'pdfocr'
          cpyJobConfig.quality = 'high'
        }
        if (cpyJobConfig.optimize === '') {
          delete cpyJobConfig.optimize
        } else {
          cpyJobConfig.profile = cpyJobConfig.optimize
          delete cpyJobConfig.optimize
        }

        _actionsConfig = _actionsConfig.map((e) => {
          if (e.actionType === 'convertFromPdf') {
            let parameters = Object.keys(cpyJobConfig)
              .filter((e1) => e1 === 'outputFormat' || e1 === 'quality')
              .map((p) => {
                return {
                  name: p,
                  value: cpyJobConfig[p],
                }
              })
            return { ...e, parameters }
          }
          if (e.actionType === 'optimize') {
            let parameters = Object.keys(cpyJobConfig)
              .filter((e) => e === 'profile')
              .map((p) => {
                return {
                  name: p,
                  value: cpyJobConfig[p],
                }
              })
            return { ...e, parameters }
          }

          return e
        })
      }
      break

    case ToolName.ADD_BARCODE: {
      let _jobConfig = { ...jobConfig }
      if (_jobConfig.pageSelectionType) {
        _jobConfig.pageSequence =
          _jobConfig.pageSelectionType === 'all'
            ? 'all'
            : _jobConfig.pageSelectionType === 'first'
            ? '1'
            : _jobConfig.customSelection.join(',')
        delete _jobConfig.pageSelectionType
        delete _jobConfig.customSelection
      }
      _jobConfig = {
        BarcodeType: _jobConfig.barcodeType,
        Text: _jobConfig.barcodeText,
        Width: Math.round(_jobConfig.width).toString(),
        Height: Math.round(_jobConfig.height).toString(),
        PageSequence: _jobConfig.pageSequence,
        AlignX: _jobConfig.xalign,
        AlignY: _jobConfig.yalign,
        Rotate: _jobConfig.rotate.toString(),
        MarginX: Math.round(_jobConfig.marginX).toString(),
        MarginY: Math.round(_jobConfig.marginY).toString(),
        BarcodeColor: _jobConfig.barcodeColor,
        BackgroundColor: _jobConfig.backgroundColor,
        HideText: _jobConfig.hideText.toString(),
      }

      _actionsConfig[0].parameters = Object.keys(_jobConfig).map((p) => ({
        name: p,
        value: _jobConfig[p],
      }))
      break
    }
    case ToolName.ADD_WATERMARK: {
      let _jobConfig = { ...jobConfig }
      if (_jobConfig.pageSelectionType) {
        _jobConfig.pageSequence =
          _jobConfig.pageSelectionType === 'all'
            ? 'all'
            : _jobConfig.pageSelectionType === 'first'
            ? '1'
            : _jobConfig.customSelection.join(',')
        delete _jobConfig.pageSelectionType
        delete _jobConfig.customSelection
      }
      if (_jobConfig.stampType === 'text') {
        _jobConfig = {
          stampType: _jobConfig.stampType,
          PageSequence: _jobConfig.pageSequence,
          AlignX: _jobConfig.xalign,
          AlignY: _jobConfig.yalign,
          Alpha: _jobConfig.opacity.toString(),
          Rotate: _jobConfig.rotate.toString(),
          Text: _jobConfig.text,
          Size: Math.ceil(_jobConfig.fontSize).toString(),
          fontFamily: _jobConfig.fontFamily,
          fontBold: _jobConfig.fontBold,
          fontItalics: _jobConfig.fontItalics,
          fontUnderline: _jobConfig.fontUnderline,
          fontColor: _jobConfig.fontColor,
          StampPosition: _jobConfig.showOnlyInPrint
            ? 'annotation'
            : _jobConfig.stampPosition,
          Mode: _jobConfig.mode,
          LineWidth: _jobConfig.lineWidth.toString(),
          MarginX: Math.round(_jobConfig.marginX).toString(),
          MarginY: Math.round(_jobConfig.marginY).toString(),
          ShowOnlyInPrint: _jobConfig.showOnlyInPrint,
        }
      } else if (_jobConfig.stampType === 'image') {
        _jobConfig = {
          stampType: _jobConfig.stampType,
          image: JSON.stringify({
            DocBlobRef: _jobConfig.docBlobRef,
            Rectangle: {
              Width: Math.ceil(_jobConfig.width),
              Height: Math.ceil(_jobConfig.height),
            },
          }),
          PageSequence: _jobConfig.pageSequence,
          AlignX: _jobConfig.xalign,
          AlignY: _jobConfig.yalign,
          Alpha: _jobConfig.opacity.toString(),
          Rotate: _jobConfig.rotate.toString(),
          StampPosition: _jobConfig.showOnlyInPrint
            ? 'annotation'
            : _jobConfig.stampPosition,
          MarginX: Math.round(_jobConfig.marginX).toString(),
          MarginY: Math.round(_jobConfig.marginY).toString(),
          ShowOnlyInPrint: _jobConfig.showOnlyInPrint,
        }
      }

      _actionsConfig[0].parameters = Object.keys(_jobConfig).map((p) => ({
        name: p,
        value: _jobConfig[p],
      }))
      break
    }
    case ToolName.ORGANIZE_PAGES: {
      const _jobConfig = { ...jobConfig }
      const actionsConfig = Object.keys(_jobConfig).map((key) => ({
        actionType: key,
        parameters: Object.keys(jobConfig[key]).map((p) => ({
          name: p,
          value: jobConfig[key][p],
        })),
      }))
      _actionsConfig = actionsConfig
      break
    }
    case ToolName.EXTRACT_RESOURCES: {
      _actionsConfig[0].parameters = Object.keys(jobConfig).map((p) => ({
        name: p,
        value: jobConfig[p],
      }))
      break
    }
    case ToolName.PDF_METADATA: {
      const cpyJobConfig = { ...jobConfig }
      const permissions = []
      if (cpyJobConfig.allowAssembly) permissions.push('assemble')
      if (cpyJobConfig.allowCopy) permissions.push('copy')
      // if (!cpyJobConfig.allowDegradedPrinting) permissions.push('digitalPrint')
      if (cpyJobConfig.allowFillIn) permissions.push('fillForms')
      if (cpyJobConfig.allowModifyAnnotations) permissions.push('annotate')
      if (cpyJobConfig.allowModifyContents) permissions.push('modify')
      if (cpyJobConfig.allowPrint) permissions.push('print')
      if (cpyJobConfig.allowScreenReaders)
        permissions.push('supportDisabilities')

      const newValues = {
        title: cpyJobConfig.title,
        subject: cpyJobConfig.subject,
        author: cpyJobConfig.author,
        producer: cpyJobConfig.producer,
        creator: cpyJobConfig.creator,
        keywords: cpyJobConfig.keywords,
        permissions: permissions.join(','),
        securityType: cpyJobConfig.securityType,
        // pdfProducer: values.pdfProducer,
      }
      _actionsConfig[0].parameters = Object.keys(newValues).map((p) => {
        if (p === 'permissions')
          return {
            name: p,
            value: newValues[p],
          }
        return {
          name: p,
          value: newValues[p],
        }
      })
    }
    default:
      break
  }

  return _actionsConfig
}
const saveFilesToOneDrive = (jobConfig, token, driveId, tenantId) => {
  const isSaveToOneDrive = jobConfig?.isSaveToOneDrive
  let saveToOneDrive
  if (isSaveToOneDrive)
    saveToOneDrive = {
      actionType: 'SaveToOneDrive',
      parameters: [
        {
          name: 'token',
          value: token,
        },
        {
          name: 'driveId',
          value: driveId,
        },
        {
          name: 'itemPath',
          value: jobConfig.customItemPath,
        },
        {
          name: 'tenantId',
          value: tenantId,
        },
      ],
    }
  const _jobConfig = { ...jobConfig }
  delete _jobConfig.customItemPath
  delete _jobConfig.isSaveToOneDrive
  return { saveToOneDrive, _jobConfig }
}

export const startPdfJob =
  (notifications, jobConfig, teamsUserCredential) =>
  async (dispatch, getState) => {
    const { hub } = getState()
    const { toolName } = notifications[0]
    const { connectionId } = hub
    const teamsContext = await app.getContext()
    var tokenRes = await teamsUserCredential.getToken('')

    const diveId = await getOneDriveInfo(
      tokenRes.token,
      teamsContext.user.tenant.id
    )

    const docs = notifications.reduce((accumulator, current) => {
      return [...accumulator, current.docs[0]]
    }, [])
    const inputFiles = docs.map((d) => {
      return {
        docIdExt: d.docIdExt,
        name: d.fileName,
        docBlobRef: d.docBlobRef,
        docMetadata: {
          size: d.docMetadata?.size ?? 0,
          pageCount: d.docMetadata?.pageCount ?? 0,
        },
      }
    })

    const { _jobConfig, saveToOneDrive } = saveFilesToOneDrive(
      jobConfig,
      tokenRes.token,
      diveId,
      teamsContext.user.tenant.id
    )

    const updatedConfig = UpdateConfig(toolName, _jobConfig)
    if (saveToOneDrive) updatedConfig.push(saveToOneDrive)
    const token =
      'MjBjOGE2YjctOTVmZi00OTdjLTk5YTMtNjcyMzUxOTlmZGM3OldJa2Z2WkgyWXV2WlhnSUwzU01Lb089S1EmSHlIZDVo'

    try {
      const jobRes = await executePdfJob(
        '',
        toolName,
        inputFiles,
        updatedConfig,
        connectionId,
        toolName,
        token
      )
      const _docs = docs.map((e) => ({
        docIdExt: e.docIdExt,
      }))

      dispatch({ type: Type.CLEAR_JOB, data: _docs })
      return jobRes
    } catch (error) {
      dispatch(errorHandler(error))
      return false
    }
  }

export const executeFileUpload = createAsyncThunk(
  'document/fileDownload',
  async (
    { tool, files, docIdExt },
    { dispatch, rejectWithValue, fulfillWithValue, getState }
  ) => {
    try {
      let connection
      const { hub } = getState()
      const { connectionId: hubConnectionId, status } = hub

      if (status != 'connected') {
        connection = await dispatch(startHub())
      }
      let connectionId = connection?.payload?.connectionId || hubConnectionId
      if (!connectionId) throw new Error('Signalr connection is not present')
      if (!files?.length) throw new Error('File is empty')
      const file = new Blob([files[0]], { type: files[0].type })
      file.name = files[0].name
      const docProcessing = { storageType: 'cdoc', toolName: tool.toolName }
      const thumbnail = 88
      const onprogress = (progress) => {
        dispatch({
          type: Type.INITIATE_FILE_UPLOAD,
          data: {
            docIdExt,
            progress,
            fileName: file.name,
            toolName: docProcessing.toolName,
            isNotification: false,
          },
        })
      }

      const data = await fileUpload(
        docProcessing.storageType,
        file,
        docIdExt,
        docProcessing.toolName,
        connectionId,
        onprogress,
        thumbnail
      )
      const docs = formatDocs(data, docIdExt, file)
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: { docIdExt, docs, ...docs[0] },
      })
      return data
    } catch (error) {
      dispatch(errorHandler(error))
      rejectWithValue(error.message)
    }
  }
)

export const downloadFile = createAsyncThunk(
  'document/fileDownload',
  async ({ name, docBlobRef, storageType }, { dispatch, rejectWithValue }) => {
    try {
      const file = {
        name: name,
        docBlobRef: docBlobRef,
        storageType: storageType,
      }
      const data = await getDownloadUrl([file])
      return data
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)

export const cleanEmptyNotification = () => (dispatch) =>
  dispatch({ type: Type.RESET_NOTIFICATION, data: {} })
export const deleteNotification = (docIdExt) => (dispatch) =>
  dispatch({
    type: Type.DELETE_NOTIFICATION,
    data: {
      docIdExt,
    },
  })
export const oneDriveNotification =
  (data = {}) =>
  (dispatch) =>
    dispatch({
      type: Type.ONE_DRIVE_NOTIFICATION,
      data,
    })

export const addFileFromJobHistory = createAsyncThunk(
  'document/addFileFromHistory',
  async ({ file }, { dispatch, getState, rejectWithValue }) => {
    try {
      let connection

      // const canAddFileRes = canAddFile(file, getState)

      // if (canAddFileRes !== 'ok') {
      //   return returnAddFileRejected(canAddFileRes)
      // }
      const { hub } = getState()
      const { connectionId: hubConnectionId, status } = hub
      if (status != 'connected') {
        connection = await dispatch(startHub())
      }
      let connectionId = connection?.payload?.connectionId || hubConnectionId
      // connectHub(dispatch, hub)

      // dispatch(mutations.uploadFileStarted(file))
      // dispatch(actions.updateFileDropStatus())
      const res = await addFileFromJobHistoryService({
        ...file,
        fileId: createId(),
        connectionId,
      })

      const pageCount =
        res.docMetadata && typeof res.docMetadata.numberOfPages === 'number'
          ? res.docMetadata.numberOfPages
          : 0

      // if (
      //   !user.paid &&
      //   pageCount > allowedPageLimit &&
      //   limitedFeatures.includes(docProcessing.toolName)
      // ) {
      //   dispatch(mutations.filePageCountExceeeded())
      // } else {
      const docIdExt = createId()

      const data = { ...res }
      data.status = 'finished'
      data.errDetails = ''
      if (
        res.status === 'error' &&
        !(res.error === 'PasswordRequired' && file.jobType === 'unlockPdf')
      ) {
        data.status = 'error'
        data.errDetails = res.error
      }
      data.docBlobRef = res.uri
      data.docMetadata = res.docMetadata
      data.pageCount = pageCount

      const docs = formatDocs([data], docIdExt, file)
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: { docIdExt, docs, ...docs[0] },
      })
      dispatch({
        type: Type.INITIATE_FILE_UPLOAD,
        data: {
          docIdExt,
          progress: 1,
          fileName: file.name,
          toolName: file.jobType,
          isNotification: false,
        },
      })
      console.log('add to job history data', docs)
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)
