export const formatDocs = (data = [], docIdExt, file) => {
  if (!data?.length) return data
  return data?.map((f) => {
    return {
      docIdExt,
      name: f?.name,
      docBlobRef: f?.docBlobRef,
      fileName: file?.name,
      status: f?.status,
      docMetadata: {
        pageCount: f?.pageCount,
        size: file?.size,
        ...(f?.docMetadata || {}),
      },
      ...((f.error && { error: f.error }) || {}),
    }
  })
}
