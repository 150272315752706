import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'

import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import { updateReadWriteField } from '../../store/teamsAuth/teamsAuthSlice'
import FormSection from '../FormSection'
import FormRow from '../FormRow'
import ButtonRow from '../ButtonRow'
import Button from '../Button'
import Popup from '../Popup'
import { ButtonRoundCorner } from '../Common'


const PopupWrapper = styled(FormSection)`
  padding: 24px !important;
`
const NeeConsentPopup = ({ onClose }) => {
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const dispatch = useDispatch()
  const getFileReadAccess = async () => {
    try {
      await teamsUserCredential.login(['Files.ReadWrite.All'])
      const res = await dispatch(updateReadWriteField({ isReadWrite: true }))
      if(updateReadWriteField.rejected(res)){
        console.error(res.payload)
      }
      // setNeedConsent(false)
      onClose()
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <Popup isCustom size="md" onClose={onClose}>
      <PopupWrapper>
        <FormRow>
          We need access to your files to read, edit, and manage them within the
          app.
        </FormRow>
        <ButtonRow center noWrap>
          <ButtonRoundCorner color={'secondary'} onClick={getFileReadAccess}>
            Authorize
          </ButtonRoundCorner>
        </ButtonRow>
      </PopupWrapper>
    </Popup>
  )
}

export default NeeConsentPopup
