import React, { useState } from 'react'
import {
  makeStyles,
  shorthands,
  tokens,
  Tab,
  TabList,
} from '@fluentui/react-components'

import JobConfig from '../JobConfig'
import categoryData from '../../assets/data/toolCategories.json'
import Carousal from '../../controls/Carousal'
import toolData from '../../assets/data/toolList.json'
import FileUpload from '../FileUpload'
import { useTranslation } from '../../i18n/useTranslation'

const useStyles = makeStyles({
  root: {
    display: 'grid',
    gridTemplateRows: '50vh 50vh',
  },
  row: {
    height: '50vh',
    display: 'grid',
    alignItems: 'start',
    justifyContent: 'center',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto',
    gridColumnGap: tokens.spacingHorizontalXXXL,
  },
  col2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  col3: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: 'repeat(4, auto)',
    gridRowGap: tokens.spacingVerticalS,
    gridColumnGap: tokens.spacingHorizontalS,
    justifyContent: 'center',
    alignItems: 'center',
  },
  twoCol: {
    gridColumnStart: 1,
    gridColumnEnd: 3,
  },
  controls: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icons: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridTemplateRows: 'auto auto',
    gridRowGap: tokens.spacingVerticalS,
    gridColumnGap: tokens.spacingHorizontalS,
    justifyContent: 'center',
  },
  avatar: {
    display: 'flex',
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  avatarText: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'left',
  },
})

const ToolTab = () => {
  const TabItem = {
    convert: 'convert',
    edit: 'edit',
    optimize: 'optimize',
  }
  const t = useTranslation()
  const [tab, setTab] = useState(TabItem.convert)

  const handleTabChange = (event, data) => {
    setTab(data.value)
  }

  const renderTabDisplay = (tab) => {
    const category = categoryData.find((e) => e.group === tab)
    return (
      <JobConfig
        Form={() => (
          <Carousal refresh={category}>
            {toolData
              ?.filter((page) => page.homeGroup === category.group)
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map((e, i) => (
                <FileUpload
                  // onFileSelect={onFileSelect}
                  key={i + e.name}
                  tool={e}
                  // fileExtensions={fileExtensions}
                  // filePickerRef={filePickerRefs.current[i]}
                  index={i}
                />
              ))}
          </Carousal>
        )}
      />
    )
    // switch (tab) {
    //     case TabItem.convert:
    //         return (<JobConfig />)
    //     case TabItem.edit:
    //         return (<JobConfig />)
    //     case TabItem.optimize:
    //         return (<JobConfig />)
    //     default:
    //         return <>common</>
    // }
  }

  const style = useStyles()
  return (
    <>
      {/* <div className={style.col2}> */}
      <TabList defaultSelectedValue={'convert'} onTabSelect={handleTabChange}>
        {categoryData.map((e, i) => (
          <Tab key={i} value={e.group}>
            {t(`lblCat_${e.catName}`)}
          </Tab>
        ))}
      </TabList>
      {renderTabDisplay(tab)}
      {/* </div> */}
    </>
  )
}

export default ToolTab
