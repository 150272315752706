import React from 'react'
import styled from 'styled-components'

import Button from '../../controls/Button'
import { useJobConfig } from '../JobConfig/JobContext'
import toolList from '../../assets/data/toolList.json'
import { PopupScreen, ToolName } from '../../utils/enums'
import { useTranslation } from '../../i18n/useTranslation'

export const BannerButton = styled(Button)`
  border-width: 2px;
  border-style: solid;
  border-radius: 16px;
  min-height: 32px;
  height: auto;
  min-width: 72px;
  padding: 3px 14px 5px;
  font-size: 14px;
  font-weight: 700;
`
const BannerPdfConverter = () => {
  const { accept, mimeTypes, fileExtensions, onFileSelect } = useJobConfig()
  const t = useTranslation();
  const tool = toolList.find((e) => e.toolName === ToolName.PDF_CONVERTER)
  return (
    <BannerButton
      onClick={() => {
        return onFileSelect(tool, null, false, PopupScreen.Action)
      }}
      color={'secondary'}
    >
      {t('btnSelectAFile')}
    </BannerButton>
  )
}

export default BannerPdfConverter
