import React, { useState, useRef, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

// import { useTranslation } from '../../hooks/TranslationContext'
import thumbnailsActions from '../../store/thumbnails/actions'
import Button from '../../controls/Button'
import Icon from '../../controls/Icon'
import ButtonRow from '../../controls/ButtonRow'
import Loader from '../../controls/Loader'
import Popup from '../../controls/Popup'
import okIcon from '../../controls/icons/ok'
import closeIcon from '../../controls/icons/close'
import { colors, device, media } from '../../theme'
import { PageList } from './PageList'
import { useTranslation } from '../../i18n/useTranslation'

const LoaderRoot = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
`

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 54px;
  position: absolute;
  right: 18px;
  top: 4px;
  cursor: pointer;
  border: none;
  background-color: transparent;

  ${media.down(device.mobile)`
    top: 0;
  `}

  i,
  svg {
    width: 30px;
    height: 30px;
  }
  svg {
    fill: ${colors.grayDarker};
  }
`

const PageListWrapper = styled.div`
  ${media.up(device.tablet)`
    max-height:74vh;
  `}
`

const Title = ({ selection }) => {
  const t = useTranslation()
  if (selection.length === 0) {
    return <h3>{t('titleSelectPages')}</h3>
  }
  if (selection.length === 1) {
    return (
      <h4>
        {selection.length} {t('titleOnePageSelected')}
      </h4>
    )
  }
  return (
    <h4>
      {selection.length} {t('titleMultiplePagesSelected')}
    </h4>
  )
}

const Header = ({ selection, onSelect, onCancel }) => {
  return (
    <>
      <Title selection={selection} />
      {selection?.length > 0 ? (
        <HeaderButton
          onClick={() => {
            onSelect()
          }}
        >
          <Icon icon={okIcon} />
        </HeaderButton>
      ) : (
        <HeaderButton
          onClick={() => {
            onCancel()
          }}
        >
          <Icon icon={closeIcon} />
        </HeaderButton>
      )}
    </>
  )
}

const Footer = ({ selection, onSelect, onCancel }) => {
  const t = useTranslation()
  return (
    <ButtonRow>
      <Button
        id="btnSelect"
        color="primary"
        onClick={onSelect}
        disabled={selection?.length < 1}
      >
        {t('btnDone')}
      </Button>
      <Button onClick={onCancel}>{t('btnCancel')}</Button>
    </ButtonRow>
  )
}

const PagePicker = (props) => {
  const {
    thumbnails,
    onSelect,
    onCancel,
    createThumbnails,
    inputFiles,
    selectedPages,
  } = props
  const t = useTranslation()
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  const zoomContainer = useRef()

  let trackPinchEvent = false

  let lastPinchDist = 0

  const initialState = {
    minPageSize: 100,
    maxPageSize: 400,
    pageList: [],
    selection: [],
    size: 220,
  }
  const [state, setState] = useState({ ...initialState })
  const { pageList, size, selection = [], maxPageSize, minPageSize } = state

  useEffect(() => {
    let nextPageList = []
    if (pageList.length < 1) {
      setState((prevState) => ({ ...prevState, selection: selectedPages }))
    }
    if (thumbnails?.thumbnails) {
      const thumbnailCount = Object.keys(thumbnails.thumbnails).length
      if (thumbnailCount > pageList.length) {
        for (let i = pageList.length + 1; i <= thumbnailCount; i++) {
          nextPageList.push({
            nr: i,
            selected: selectedPages.includes(i),
          })
        }
        setState((prev) => ({ ...prev, pageList: nextPageList }))
      }
    }
  }, [thumbnails])
  useEffect(() => {
    if (
      fileReady &&
      (!thumbnails.extDocId || thumbnails.extDocId != inputFiles[0]?.docIdExt)
    ) {
      createThumbnails(inputFiles[0])

      const maxScreenWidth = Math.floor(window.innerWidth) - 60
      const maxImageWidth =
        maxScreenWidth < maxPageSize ? maxScreenWidth : maxPageSize

      let initialSize = size
      if (maxImageWidth / 2 < initialSize) {
        initialSize = maxImageWidth / 2
      }

      setState((prev) => ({
        ...prev,
        maxPageSize: maxImageWidth,
        size: initialSize,
      }))

      const modalElm = document.getElementById('modal')
      modalElm.addEventListener('mousewheel', handleMouseWheel)
      modalElm.addEventListener('touchmove', handleTouchMove)
      modalElm.addEventListener('touchend', handleTouchEnd)
    }

    return () => {
      const modalElm = document.getElementById('modal')
      modalElm.removeEventListener('mousewheel', handleMouseWheel)
      modalElm.removeEventListener('touchmove', handleTouchMove)
      modalElm.removeEventListener('touchend', handleTouchEnd)
    }
  }, [fileReady])

  const handleMouseWheel = (e) => {
    if (e.ctrlKey) {
      e.preventDefault()

      let newSize = size + Math.sign(e.deltaY) * -25
      newSize = Math.min(maxPageSize, newSize)
      newSize = Math.max(minPageSize, newSize)
      setState((prev) => ({ ...prev, size: newSize }))
    }
  }

  const handleTouchMove = (e) => {
    if (e.touches.length > 1) {
      e.preventDefault()
      const x1 = e.touches[0].clientX
      const y1 = e.touches[0].clientY
      const x2 = e.touches[1].clientX
      const y2 = e.touches[1].clientY
      const vx = Math.abs(x1 - x2)
      const vy = Math.abs(y1 - y2)
      const dist = Math.sqrt(vx * vx + vy * vy)

      if (!trackPinchEvent) {
        trackPinchEvent = true
        lastPinchDist = dist
      } else {
        const delta = dist - lastPinchDist

        let newSize = size + delta
        newSize = Math.min(maxPageSize, newSize)
        newSize = Math.max(minPageSize, newSize)
        setState((prev) => ({ ...prev, size: newSize }))

        lastPinchDist = dist
      }
    }
  }

  const handleTouchEnd = () => {
    trackPinchEvent = false
  }

  const handleToggle = (pageNr) => {
    const newList = pageList.map((page) => {
      if (page.nr === pageNr) {
        return {
          ...page,
          selected: !page.selected,
        }
      }
      return page
    })
    const selection = newList.filter((p) => p.selected).map((p) => p.nr)
    setState((prev) => ({ ...prev, pageList: newList, selection }))
  }

  const handleOnSelect = () => {
    if (onSelect) {
      const selection = pageList.filter((p) => p.selected).map((p) => p.nr)
      onSelect({
        selectedPages: selection,
        totalPages: pageList.length,
      })
    }
  }

  const handleCancel = () => {
    if (onCancel) {
      onCancel()
    }
  }

  return (
    <Popup
      size="xl"
      header={() => (
        <Header
          selection={selection}
          onSelect={handleOnSelect}
          onCancel={handleCancel}
        />
      )}
      footer={() => (
        <Footer
          selection={selection}
          onSelect={handleOnSelect}
          onCancel={handleCancel}
        />
      )}
    >
      <PageListWrapper ref={zoomContainer} className="zoom-container">
        {pageList && pageList.length > 0 ? (
          <PageList
            pageSize={size}
            pages={pageList}
            thumbnails={thumbnails.thumbnails}
            onToggle={handleToggle}
          />
        ) : (
          <LoaderRoot>
            <Loader />
          </LoaderRoot>
        )}
      </PageListWrapper>
    </Popup>
  )
}

const mapStateToProps = (state) => ({
  thumbnails: state.thumbnails,
  // inputFiles: state.docProcessing.inputFiles,
})

const mapDispatchToProps = {
  ...thumbnailsActions,
}

export default connect(mapStateToProps, mapDispatchToProps)(PagePicker)
