import React, { useCallback, useEffect, useMemo, useState } from 'react'
import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import Checkbox from '../../../../controls/Checkbox'
import FormSection from '../../../../controls/FormSection'
import pdfFileIcon from '../../../../controls/icons/pdfFile'
import globeIcon from '../../../../controls/icons/globe'
import compressIcon from '../../../../controls/icons/compress'
import { isMimeTypeSupported } from '../../../../utils/mimeTypes'
import { useJobConfig } from '../../../JobConfig/JobContext'
import { ToolName } from '../../../../utils/enums'
import { useTranslation } from '../../../../i18n/useTranslation'

const PdfToWordConfig = ({ onChange, form, inputFiles, toolName }) => {
  const { updateActionConfig } = useJobConfig()
  const t = useTranslation('tool_pdfConverter')
  const [showOcrOption, setShowOcrOption] = useState(false)
  const hasImageFiles = useMemo(
    () =>
      (inputFiles &&
        inputFiles.filter((f) => isMimeTypeSupported(f?.name, 'images'))
          .length) ||
      0,
    [inputFiles]
  )
  const fileSize = useMemo(() => inputFiles && inputFiles.length, [inputFiles])

  useEffect(() => {
    if (hasImageFiles > 0) {
      setShowOcrOption(hasImageFiles > 0)
    } else setShowOcrOption(false)
  }, [hasImageFiles])

  useEffect(() => {
    if (fileSize)
      updateActionConfig({
        fileSize: fileSize || 0,
      })
  }, [fileSize])
  useEffect(() => {
    updateActionConfig({
      optimize: '',
      merge: false,
      ocr: false,
    })
  }, [])
  return (
    <FormSection>
      <FormRow
        label={t('lblOptimize')}
        hint={t(`optimizeDesc_${form.optimize}`)}
      >
        <ButtonGroup
          name="optimize"
          items={[
            {
              id: 'btnOptimizeNone',
              value: '',
              label: t('optimizeNone'),
              icon: pdfFileIcon,
            },
            {
              id: 'btnOptimizeWeb',
              value: 'web',
              label: t('optimizeWeb'),
              icon: globeIcon,
            },
            {
              id: 'btnOptimizeSize',
              value: 'max',
              label: t('optimizeSize'),
              icon: compressIcon,
            },
          ]}
          type="button"
          value={form.optimize}
          onChange={onChange}
        />
      </FormRow>

      {(showOcrOption || inputFiles.length > 1) &&
        [ToolName.PDF_CONVERTER, ToolName.IMAGE_TO_PDF].includes(toolName) && (
          <div>
            {inputFiles.length > 1 && (
              <div>
                <Checkbox
                  id="chkMerge"
                  name="merge"
                  onChange={onChange}
                  checked={form.merge}
                >
                  {t('lblMerge')}
                </Checkbox>
              </div>
            )}
            {showOcrOption &&
              [ToolName.PDF_CONVERTER, ToolName.IMAGE_TO_PDF].includes(
                toolName
              ) && (
                <div>
                  <Checkbox
                    id="chkOcr"
                    name="ocr"
                    onChange={onChange}
                    checked={form.ocr}
                  >
                    {t('lblOcr')}
                  </Checkbox>
                </div>
              )}
          </div>
        )}
    </FormSection>
  )
}

export default PdfToWordConfig
