import { Fragment, useEffect, useState } from 'react'
import styled, { css } from 'styled-components'

import { colors, layout, device } from '../../theme'

const ScrollWrapper = styled.div`
  max-width: 100%;
  overflow-x: auto;
`

const Table = styled.table`
  ${(props) =>
    props.loading === 'true' &&
    css`
      opacity: 0.5;
    `}
`

const Cell = styled.td`
  ${(props) =>
    props.$align === 'right' &&
    css`
      text-align: right;
    `}
`

const HeaderCell = styled.th`
  ${(props) =>
    props.$align === 'right'
      ? css`
          text-align: right !important;
        `
      : css`
          text-align: left;
        `}
`

const List = styled.div`
  ${(props) =>
    props.loading === 'true' &&
    css`
      opacity: 0.5;
    `}
`

const ListItem = styled.div`
  background-color: #fff;
  box-shadow: ${layout.elevate(1)};
  padding: 4px 20px;
  margin: 10px 0;
`

const Dl = styled.dl`
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 1fr 2fr;
  margin: 10px 0;
`
const Dt = styled.dl`
  margin: 0;
  color: ${colors.grayDark};
`
const Dd = styled.dl`
  margin: 0;
`

export default (props) => {
  const { rows, data, loading, scrollWrapper, onRowClick } = props

  const [isMobile, setIsMobile] = useState(false)

  const tableCssClasses = ['table']
  if (typeof onRowClick == 'function') {
    tableCssClasses.push('table--hover')
  }

  useEffect(() => {
    const handleResize = () => {
      var rect = document.body.getBoundingClientRect()
      setIsMobile(rect.width <= device.mobile)
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  function handleRowClick(id) {
    if (typeof onRowClick === 'function') {
      onRowClick(id)
    }
  }

  if (isMobile) {
    return (
      <List loading={loading}>
        {data.map((item, index) => (
          <ListItem
            key={index}
            onClick={() => {
              if (typeof onRowClick === 'function') {
                onRowClick(item)
              }
            }}
          >
            {rows.map((row, i) => (
              <Dl key={i}>
                <Dt>{row.title}</Dt>
                <Dd>
                  {row.renderItem ? row.renderItem(item) : item[row.field]}
                </Dd>
              </Dl>
            ))}
          </ListItem>
        ))}
      </List>
    )
  }

  function renderTable() {
    return (
      <Table loading={loading} className={tableCssClasses.join(' ')}>
        <colgroup>
          {rows.map((row, index) => (
            <col
              key={index}
              style={{ width: row.width ? row.width : 'auto' }}
            />
          ))}
        </colgroup>
        <thead>
          <tr>
            {rows.map((row, index) => (
              <HeaderCell key={index} $align={row.align}>
                {row.renderHeader ? row.renderHeader(row) : row.title}
              </HeaderCell>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              onClick={() => {
                if (typeof onRowClick === 'function') {
                  onRowClick(item)
                }
              }}
            >
              {rows.map((row, i) => (
                <Cell key={i} $align={row.align}>
                  {row.renderItem ? row.renderItem(item) : item[row.field]}
                </Cell>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  return scrollWrapper ? (
    <ScrollWrapper>{renderTable()}</ScrollWrapper>
  ) : (
    renderTable()
  )
}
