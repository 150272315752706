import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
  createContext,
} from 'react'
import { getTeamsLanguage } from '../utils/translationHelper'
import { TeamsFxContext } from '../components/TeamFxContextProvider'
import i18n from '.'

const LanguageContext = createContext()

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState('en')
  const { context } = useContext(TeamsFxContext)
  const locale = useMemo(() => context?.app?.locale, [context?.app?.locale])

  const changeLanguage = (lang) => {
    i18n.setLanguage(lang)
    setLanguage(lang)
  }
  useEffect(() => {
    const runEffect = async () => {
      if (locale) {
        const lang = await getTeamsLanguage()
        changeLanguage(lang)
      }
    }
    runEffect()
  }, [locale])

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageProvider
export const useLanguage = () => useContext(LanguageContext)
