import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import {
  TableBody,
  TableCell,
  TableRow,
  Table,
  TableHeader,
  TableHeaderCell,
  TableCellLayout,
  useTableFeatures,
  useTableSelection,
  Spinner,
  createTableColumn,
} from '@fluentui/react-components'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import _ from 'lodash'
import { useData } from '@microsoft/teamsfx-react'

import {
  breadCrumbNavigate,
  fetchItemsForPath,
  resetDrive,
} from '../../store/drive/Slice/oneDrivePickerSlice'
import { formatFileSize } from '../../utils/helper'
import { TeamsFxContext } from '../TeamFxContextProvider'
import {
  CustomFileIcon,
  CustomTableTd,
  FolderButton,
  FolderButtonWrapper,
  NoDataText,
  TheeDotsCellLayout,
  FooterBtn,
  FooterBtnWrapper,
  CustomButton,
  Content,
} from './styles'
import { login } from '../../store/auth/action'
import {
  DocumentList_list,
  DocumentList,
  DocumentListHeader,
  Breadcrumb,
  BreadCrumbNav,
  BreadcrumbList,
  BreadcrumbItem,
} from '../../container/Document/styles'
import DriveButton from '../../controls/DriveButton'
import {
  uploadFileToOneDrive,
  createFolderInOneDrive,
} from '../../store/drive/Actions/oneDriveFileActions'
import Button from '../../controls/Button'
import { LoadingStates, MessageType } from '../../utils/enums'
import { useMessage } from '../../controls/MessageBox'
import CreateFolder from '../../controls/CreateFolder'
import Popup from '../../controls/Popup'
import InputField from '../../controls/InputField'
import FormSection from '../../controls/FormSection'
import FormRow from '../../controls/FormRow'
import AuthPopup from '../../controls/AuthPopup'
import ButtonRow from '../../controls/ButtonRow'
import {
  updateReadWriteField,
  updateUserInfo,
} from '../../store/teamsAuth/teamsAuthSlice'
import ConsentBanner from '../../controls/ConsentBanner'
import { unwrapResult } from '@reduxjs/toolkit'
import { useTranslation } from '../../i18n/useTranslation'

// import FormRow from '../../controls/InputR'

export const AddFolderBtn = styled(Button)`
  border: none;
  height: fit-content;
  width: fit-content;
  & > div {
    width: 18px;
    height: 18px;
  }
`
// const CustomTableTd = styled.td`
//   text-align: center;
//   margin: 20px 0;
// `

const OneDriveFilePicker = (props) => {
  const {
    ToggleMenu,
    className,
    showFolderBtn = false,
    showUploadBtn = false,
  } = props
  const t = useTranslation()
  const { showMessage } = useMessage()
  const breadCrumbRef = useRef([])
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const isReadWrite = useSelector((state) => state.teamsAuth.isReadWrite)
  const isReadFiles = useSelector((state) => state.teamsAuth.isReadFiles)
  const { items, history, isLoading } = useSelector((state) => state.oneDrive)
  const initialOneDriveState = {
    itemId: '',
    driveId: '',
    name: '',
  }
  const [oneDriveFile, setOneDriveFile] = useState(initialOneDriveState)
  const [values, setValues] = useState({})
  const [errors, setErrors] = useState({})
  const [isPopup, setIsPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isAuthPopup, setIsAuthPopup] = useState(false)
  const [isConsentPopup, setIsConsentPopup] = useState(false)
  // const [needConsent, setNeedConsent] = useState(false)
  const closePopup = () => {
    setIsPopup(false)
    setErrors({})
    setValues({})
    setIsConsentPopup(false)
  }
  const closeConsentPopup = () => setIsConsentPopup(false)

  const dispatch = useDispatch()
  const notificationList = useSelector((state) =>
    state.notification.filter((e) => e?.isOneDrive)
  )
  const notificationLength = useMemo(
    () => notificationList.length,
    [notificationList]
  )
  const Loading = [LoadingStates.FULFILLED, LoadingStates.REJECTED]
  // const {
  //   loading: isDataLoad,
  //   data,
  //   error,
  //   reload,
  // } = useData(async () => {
  //   if (!teamsUserCredential) {
  //     throw new Error('TeamsFx SDK is not initialized.')
  //   }
  //   try {
  //     if (!needConsent) {
  //       const token = await teamsUserCredential.getToken([
  //         'Files.ReadWrite.All',
  //         // 'Sites.ReadWrite.All',
  //       ])
  //       if (token) {
  //         console.log('User already has permission.')

  //         return true
  //       }
  //     } else {
  //       await teamsUserCredential.login(['Files.ReadWrite.All'])
  //       console.log('User logged in and permission granted.')
  //       return true
  //     }
  //   } catch (error) {
  //     setNeedConsent(true)
  //     console.error('Error checking or requesting permissions:', error)
  //     return false
  //   }
  // })
  const fetch = async () => {
    if (!Loading.includes(isLoading) && !history.length && isReadFiles) {
      setOneDriveFile(initialOneDriveState)
      dispatch(
        fetchItemsForPath(
          teamsUserCredential,
          oneDriveFile.itemId,
          oneDriveFile.driveId,
          oneDriveFile.name
        )
      )
    }
  }
  useEffect(() => {
    fetch()
  }, [isReadFiles, isReadWrite])

  // useEffect(() => {
  //   const index = history.length ? history.length - 1 : 0

  //   if (notificationLength && breadCrumbRef.current[index]) {
  //     breadCrumbRef.current[index] && breadCrumbRef.current[index].click()
  //     setLoading(false)
  //   }
  // }, [notificationLength])

  const closeLoginConcentPopup = () => {
    setIsAuthPopup(false)
  }
  const SignIn = () => {
    dispatch(login())
    closeLoginConcentPopup()
  }

  const openLoginConcentPopup = () => {
    setIsAuthPopup(true)
  }

  const openPopup = (event) => {
    if (!isAuthenticated) {
      event.preventDefault()
      event.stopPropagation()
      openLoginConcentPopup && openLoginConcentPopup()
      return
    }
    setIsPopup(true)
  }
  const handleNavigation = async (callback) => {
    if (!isReadFiles) {
      setIsConsentPopup(true)
    } else {
      try {
        const res = await callback()
        setLoading(false)
      } catch (error) {
        dispatch(updateReadWriteField({ isReadFiles: false }))
        setIsConsentPopup(true)
      }
    }
  }
  const fileSelectHandler = (event, fileId, driveId, name) => {
    setOneDriveFile({ itemId: fileId, driveId })
    handleNavigation(() =>
      dispatch(fetchItemsForPath(teamsUserCredential, driveId, fileId, name))
    )
  }
  const handleUploadClick = (event) => {
    if (!isAuthenticated) {
      event.preventDefault()
      event.stopPropagation()
      openLoginConcentPopup && openLoginConcentPopup()
    }
  }
  const fileUploadHandler = async (event) => {
    try {
      const files = event.target.files
      const file = files[0]
      const itemPath = history.reduce(
        (acc, curr) => (curr.name ? acc.concat(curr.name) : acc),
        []
      )
      console.log(1)
      itemPath.push(file?.name)
      // setLoading(true)
      dispatch(
        uploadFileToOneDrive({
          teamsUserCredential,
          name: file?.name,
          itemPath: itemPath.join('/'),
          file,
        })
      )
      // const res = unwrapResult(result)

      // showMessage('File Uploaded SuccessFully', MessageType.Success)
    } catch (error) {}
  }
  const checkConsent = (event, callback) => {
    if (!isReadWrite) {
      event.preventDefault()
      event.stopPropagation()

      setIsConsentPopup(true)
    } else {
      callback && callback(event)
    }
  }
  const authorize = async () => {
    if (!teamsUserCredential) {
      throw new Error('TeamsFx SDK is not initialized.')
    }
    try {
      const res = await teamsUserCredential.login([
        'User.Read',
        'Files.Read.All',
        // 'Sites.Read.All'
      ])
      dispatch(updateUserInfo({ isReadFiles: true }))
      await dispatch(resetDrive())
    } catch (err) {}
  }

  const getFileReadAccess = async () => {
    try {
      await teamsUserCredential.login(['Files.ReadWrite.All'])
      dispatch(updateReadWriteField({ isReadWrite: true }))
      await dispatch(resetDrive())
      setIsConsentPopup(false)
    } catch (error) {
      console.log(error)
    }
  }

  const columns = [
    createTableColumn({
      columnId: 'file',
    }),
    createTableColumn({
      columnId: 'size',
    }),
  ]
  const { getRows } = useTableFeatures(
    {
      columns,
      items: items || [],
    },
    [
      useTableSelection({
        selectionMode: 'multiselect',
        defaultSelectedItems: new Set([]),
      }),
    ]
  )
  const rows = getRows((row) => {
    // const selected = isRowSelected(row.rowId)
    return {
      ...row,
      onClick: (e) =>
        row.item.isFolder &&
        fileSelectHandler(e, row.item.itemId, row.item.driveId, row.item.name),
    }
  })
  const isValidateFolderName = (field, value) => {
    const folderName = value
    const invalidChars = /[\\/:*?"<>|]/
    const reservedNames = [
      'CON',
      'PRN',
      'AUX',
      'NUL',
      'COM1',
      'COM2',
      'COM3',
      'COM4',
      'COM5',
      'COM6',
      'COM7',
      'COM8',
      'COM9',
      'LPT1',
      'LPT2',
      'LPT3',
      'LPT4',
      'LPT5',
      'LPT6',
      'LPT7',
      'LPT8',
      'LPT9',
    ]

    let errorMessage = ''

    if (folderName.trim() === '') {
      errorMessage = t('errLblFolderNameEmpty')
    } else if (folderName.length > 255) {
      errorMessage = t('errLblFolderNameCharRestrict')
    } else if (invalidChars.test(folderName)) {
      errorMessage = t('errLblFolderNameCharInvalid')
    } else if (reservedNames.includes(folderName.toUpperCase())) {
      errorMessage = t('errLblFolderNameNotReserve')
    }

    if (errorMessage) {
      setErrors((prev) => ({ ...prev, [field]: errorMessage }))
      return false
    } else {
      setErrors(_.omit({ ...errors }, [field]))
      return true
    }
  }
  const isValid = () => {
    if (_.isEmpty(errors)) return true
    return false
  }

  const submit = async () => {
    if (isValid()) {
      const itemPath = history.reduce(
        (acc, curr) => (curr.name ? acc.concat(curr.name) : acc),
        []
      )
      // setLoading(true)
      const index = history.length ? history.length - 1 : 0
      closePopup()
      const res = await dispatch(
        createFolderInOneDrive({
          teamsUserCredential,
          folderName: values?.folderName,
          absolutePath: itemPath.join('/'),
        })
      )
      // if (res.error) showMessage('Failed to create folder', MessageType.Error)
      // breadCrumbRef?.current[index] && breadCrumbRef.current[index]?.click()
      // showMessage('Folder created successfully', MessageType.Success)
    } else {
      const error = Object.values(errors)
      showMessage(error, MessageType.Error)
    }
  }

  const handleFieldChange = (field, value) => {
    if (field === 'folderName') {
      isValidateFolderName(field, value)
      setValues((prev) => ({ ...prev, [field]: value }))
    }
  }
  const renderPopupFooter = () => {
    return (
      <FooterBtnWrapper>
        <FooterBtn color="secondary" onClick={submit} disabled={loading}>
          {t('btnCreate')}
        </FooterBtn>
        <FooterBtn onClick={closePopup}> {t('btnCancel')}</FooterBtn>
      </FooterBtnWrapper>
    )
  }
  return (
    <>
      {isConsentPopup && (
        <Popup onClose={closeConsentPopup} isCustom>
          <Content>
            <FormSection>
              <FormRow>{t('lblOneDriveFilesReadEditPermission')}</FormRow>
              <ButtonRow center noWrap>
                <CustomButton color={'secondary'} onClick={getFileReadAccess}>
                  {t('btnAuthorize')}
                </CustomButton>
              </ButtonRow>
            </FormSection>
          </Content>
        </Popup>
      )}
      {isPopup && (
        <Popup
          onClose={closePopup}
          title={t('lblCreateFolder')}
          footer={renderPopupFooter}
        >
          <FormSection>
            <InputField
              label={t('lblFolderName')}
              errorMessage={errors?.folderName}
              value={values?.folderName}
              onChange={(event) =>
                handleFieldChange('folderName', event.target.value)
              }
            />
          </FormSection>
        </Popup>
      )}
      {isAuthPopup && (
        <AuthPopup onClose={closeLoginConcentPopup} SignIn={SignIn} />
      )}
      <DocumentList className={className ? className : ''}>
        <DocumentListHeader>
          <Breadcrumb>
            <BreadCrumbNav>
              <BreadcrumbList>
                {(history || []).map((crumb, i) => (
                  <BreadcrumbItem
                    ref={(el) => (breadCrumbRef.current[i] = el)}
                    onClick={() =>
                      handleNavigation(() =>
                        dispatch(
                          breadCrumbNavigate(
                            teamsUserCredential,
                            crumb.driveId,
                            crumb.itemId,
                            i
                          )
                        )
                      )
                    }
                  >
                    {crumb?.name || t('btnOneDrive')}
                  </BreadcrumbItem>
                ))}
                {/* <BreadcrumbItem>{DocumentScreen[e]}</BreadcrumbItem> */}
              </BreadcrumbList>
            </BreadCrumbNav>
          </Breadcrumb>
          {showUploadBtn && (
            <DriveButton
              onChange={fileUploadHandler}
              onClick={(event) => checkConsent(event, handleUploadClick)}
              iconName={'fileUpload'}
              label={t('btnUpload')}
            />
          )}
          {showFolderBtn && (
            <CreateFolder onClick={(event) => checkConsent(event, openPopup)} />
          )}
        </DocumentListHeader>

        <DocumentList_list>
          <Table aria-label="Table with multiselect">
            <TableHeader>
              <TableRow>
                <TableHeaderCell>{t('lblName')}</TableHeaderCell>

                <TableHeaderCell>{t('lblSize')}</TableHeaderCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {!isReadFiles ? (
                <tr>
                  <CustomTableTd colSpan="4">
                    <ConsentBanner onClick={authorize} />
                  </CustomTableTd>
                </tr>
              ) : (
                <>
                  {
                    // !isAuthenticated ? (
                    //   <tr>
                    //     <CustomTableTd colSpan="4">
                    //       <Button color={'primary'} onClick={() => dispatch(login())}>
                    //         Sign In
                    //       </Button>
                    //     </CustomTableTd>
                    //   </tr>
                    // ) :
                    !Loading.includes(isLoading) || loading ? (
                      <tr>
                        <CustomTableTd colSpan="4">
                          <Spinner />
                        </CustomTableTd>
                      </tr>
                    ) : !rows?.length ? (
                      <TableRow>
                        <CustomTableTd colSpan="4">
                          <NoDataText>{t('lblNoData')}</NoDataText>
                        </CustomTableTd>
                      </TableRow>
                    ) : (
                      rows.map(
                        ({
                          item,
                          selected,
                          onClick,
                          onKeyDown,
                          appearance,
                        }) => (
                          <TableRow key={item.itemId} onClick={onClick}>
                            <TableCell>
                              {item.isFolder ? (
                                <TableCellLayout
                                  media={
                                    <FolderButtonWrapper>
                                      <FolderButton toolName={'folder'} />
                                    </FolderButtonWrapper>
                                  }
                                >
                                  {item.name}
                                </TableCellLayout>
                              ) : (
                                <TableCellLayout
                                  media={<CustomFileIcon name={item.name} />}
                                >
                                  {item.name}
                                </TableCellLayout>
                              )}
                            </TableCell>

                            <TableCell>
                              {!item.isFolder ? formatFileSize(item.size) : ''}
                            </TableCell>

                            {ToggleMenu && !item.isFolder && (
                              <TableCell>
                                <TheeDotsCellLayout>
                                  <ToggleMenu
                                    item={{ ...item, id: item.itemId }}
                                  />
                                </TheeDotsCellLayout>
                              </TableCell>
                            )}
                          </TableRow>
                        )
                      )
                    )
                  }
                </>
              )}
            </TableBody>
          </Table>
        </DocumentList_list>
      </DocumentList>
    </>
  )
}

export default OneDriveFilePicker
