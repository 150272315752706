export const copyTextToClipboard = async (text) => {
  if (
    navigator.clipboard &&
    typeof navigator.clipboard.writeText === 'function'
  ) {
    try {
      await navigator.clipboard.writeText(text)
      return true
    } catch (err) {
      console.warn('Modern Clipboard API failed, falling back:', err)
    }
  }

  // If modern API fails or isn't available, use the fallback
  const el = document.createElement('textarea')
  el.value = text
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  const successful = document.execCommand('copy')
  document.body.removeChild(el)

  if (successful) {
    return true
  } else {
    return false // Failure
  }
}
