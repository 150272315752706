import { api } from '../initFetch'

export const sendDocuments = async (
  from,
  to,
  files,
  templateFields,
  language,
  toolName,
  fileStorageId,
  docBlobRefs,
  sendAsZip
) => {
  const res = await api.post('/p4m/Document/SendDocuments', {
    sendAsZip,
    docBlobRefs,
    sendType: 'send',
    sendGridTemplateData: {
      ...templateFields,
      from: { email: from },
      tos: [{ email: to }],
    },
    language,
    duration: 336,
    toolName,
    fileStorageId,
  })
  return res.json
}
