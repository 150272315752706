import { useEffect, useState, useRef, useCallback } from 'react'

import Icon from '../Icon'

const itemClassNames = (item, value) => {
  const classnames = ['btnGroup__btn']
  if (item.value === value) {
    classnames.push('btnGroup__btn--selected')
  }
  if (item.disabled) {
    classnames.push('btnGroup__btn--disabled')
  }
  return classnames.join(' ')
}

const ButtonGroup = (props) => {
  const { items, name, value, disabled, onClick, onChange, type, className } =
    props
  const wrapperEl = useRef(null)

  const [bgPos, setBgPos] = useState({
    visible: false,
    left: 0,
    width: 0,
  })

  const updatePos = useCallback(() => {
    if (wrapperEl.current) {
      const selectedElm = wrapperEl.current.querySelector(`[value="${value}"]`)

      if (selectedElm) {
        setBgPos({
          visible: true,
          left: selectedElm.parentElement.offsetLeft,
          top: selectedElm.parentElement.offsetTop,
          width: selectedElm.parentElement.offsetWidth,
          height: selectedElm.parentElement.offsetHeight,
        })
      } else {
        setBgPos({ visible: false, left: 0, width: 0 })
      }
    }
  })

  useEffect(() => {
    updatePos()
  }, [value])

  useEffect(() => {
    window.addEventListener('resize', updatePos)
    return () => {
      window.removeEventListener('resize', updatePos)
    }
  })

  if (!name) {
    throw new Error('name property for ButtonGroup is required')
  }

  const classNames = ['btnGroup']
  classNames.push(`btnGroup--i${items.length}`)
  if (disabled) {
    classNames.push('disabled')
  }

  if (type === 'button') {
    classNames.push('type-button')
  }

  if (type === 'radio') {
    classNames.push('type-radio')
  }
  if (className) classNames.push(className)

  return (
    <div ref={wrapperEl} className={classNames.join(' ')}>
      {items &&
        items.map((item) => (
          <label
            key={item.value}
            id={item.id}
            htmlFor={`lbl_${item.value}`}
            // className={item.value === value ? 'selected' : null}
            className={itemClassNames(item, value)}
            onClick={(e) => {
              if (onClick && !item.disabled) {
                onClick({ target: { value: item.value, name } }, e)
              }
            }}
          >
            <input
              type="radio"
              name={name}
              id={`lbl_${item.value}`}
              value={item.value}
              disabled={disabled || (!disabled && item.disabled)}
              checked={item.value === value}
              onChange={onChange}
            />
            {item.icon && <Icon icon={item.icon} />}
            <span className="btnGroup__label">{item.label}</span>
          </label>
        ))}
      {bgPos.visible && (
        <div
          className="btnGroup__bg"
          style={{ left: bgPos.left, width: bgPos.width }}
        />
      )}
    </div>
  )
}

export default ButtonGroup
