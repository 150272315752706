import React, { createElement, useContext } from 'react'
import { useDispatch } from 'react-redux'
import _ from 'lodash'
import { useNavigate } from 'react-router-dom'

import FileIcon from '../../components/File/FileIcon'
import allPages from '../../controls/icons/allPages'
import close from '../../controls/icons/close'
import Download from '../../controls/icons/download'
import convert from '../../controls/icons/convertDraft'
import { useJobConfig } from '../JobConfig/JobContext'

import { isMimeTypeSupported } from '../../utils/mimeTypes'
import { TeamsFxContext } from '../TeamFxContextProvider'
import { downloadFile } from '../../store/viewer/downloadApiSlice'
import {
  FileDesc,
  FileInfoBox,
  FileName,
  HorizontalLine,
  Item,
  ItemButton,
  List,
  ListBox,
  Preview,
  PreviewCloseButton,
  PreviewCustomIcon,
  PreviewMultiple,
  PreviewNumSelected,
  TableBoardWrapper,
  ItemIcon,
  ItemToolIcon,
} from './styles'
import { getFileFromURL, getToolByName, getTools } from '../../utils/helper'
import { PopupScreen, ToolName } from '../../utils/enums'
import { colors, device } from '../../theme'
import { ViewportContext } from '../../hooks/ViewportContext'

const TableBoard = () => {
  const { selectedRows, clearTableSelection, onFileSelect, openToolPopup } =
    useJobConfig()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const viewport = useContext(ViewportContext)
  const { teamsUserCredential } = useContext(TeamsFxContext)
  function getFileType(fileName = '') {
    // Extract the file extension
    const extension = fileName.split('.').pop().toUpperCase()
    return extension
  }
  const downloadOneDrive = async (itemId, driveId, name) => {
    try {
      const res = await dispatch(
        downloadFile({ teamsUserCredential, itemId, driveId })
      )
      if (res.error) {
        return
      }
      const link = document.createElement('a')
      link.href = res.payload
      link.download = name
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      return
    } catch (error) {
      return
    }
  }

  const download = async () => {
    await Promise.allSettled(
      selectedRows?.map(
        async (e) => await downloadOneDrive(e.id, e.driveId, e.name)
      )
    )
  }

  const pdfAction = async (tool) => {
    let file
    try {
      const _fileUrl = await dispatch(
        downloadFile({
          teamsUserCredential,
          itemId: selectedRows[0].id,
          driveId: selectedRows[0].driveId,
        })
      )
      if (_fileUrl.error) return
      file = await getFileFromURL(_fileUrl.payload, selectedRows[0].name)
      // fileUploadHandler(tool, [file], id)
      onFileSelect(tool, [file], true, PopupScreen.StartJob, null)
    } catch (error) {
      return
    }
  }

  const convert = async () => {
    const tempFileList = await Promise.all(
      selectedRows.map(async (e) => {
        const _fileUrl = await dispatch(
          downloadFile({
            teamsUserCredential,
            itemId: e.id,
            driveId: e.driveId,
          })
        )
        if (_fileUrl.error) return null
        let file
        try {
          file = await getFileFromURL(_fileUrl.payload, e.name)
        } catch (error) {
          return null
        }
        const tool = getToolByName(ToolName.PDF_CONVERTER)
        return onFileSelect(
          tool,
          [file],
          true,
          PopupScreen.StartJob,
          null,
          false
        )
      })
    )
    const fileLIst = tempFileList.filter((e) => e !== null)
    if (fileLIst.length) openToolPopup(PopupScreen.StartJob)
  }
  const merge = async () => {
    const tempFileList = await Promise.all(
      selectedRows.map(async (e) => {
        const _fileUrl = await dispatch(
          downloadFile({
            teamsUserCredential,
            itemId: e.id,
            driveId: e.driveId,
          })
        )
        if (_fileUrl.error) return null
        let file
        try {
          file = await getFileFromURL(_fileUrl.payload, e.name)
        } catch (error) {
          return null
        }
        const tool = getToolByName(ToolName.MERGE_PDF)
        return onFileSelect(
          tool,
          [file],
          true,
          PopupScreen.StartJob,
          null,
          false
        )
      })
    )
    const fileLIst = tempFileList.filter((e) => e !== null)
    if (fileLIst.length) openToolPopup(PopupScreen.StartJob)
  }

  const getListItems = (selectedRows) => {
    const isPdf = _.every(selectedRows, (e) =>
      isMimeTypeSupported(e.name, 'pdf')
    )
    const convertable = _.every(selectedRows, (e) =>
      isMimeTypeSupported(e.name, 'convertable')
    )
    const pdfConverterTool = getToolByName(ToolName.PDF_CONVERTER)
    const mergePdfTool = getToolByName(ToolName.MERGE_PDF)
    if (selectedRows?.length === 1 && isPdf) {
      const tools = getTools(selectedRows[0])
      return tools.map((e) => {
        const tool = getToolByName(e.name)
        return {
          label: tool.title,
          action: () => pdfAction(tool),
          iconName: tool.iconName,
          toolClass: tool.toolClass,
        }
      })
    }
    if (selectedRows?.length > 0) {
      let tempArr = []
      if (convertable)
        tempArr.push({
          label: pdfConverterTool.title,
          action: convert,
          iconName: pdfConverterTool.iconName,
          toolClass: pdfConverterTool.toolClass,
        })
      if (isPdf && selectedRows?.length > 1)
        tempArr.push({
          label: mergePdfTool.title,
          action: merge,
          iconName: mergePdfTool.iconName,
          toolClass: mergePdfTool.toolClass,
        })

      return tempArr
    }
    return []
  }

  const getList = () => {
    return (
      <List>
        {getListItems(selectedRows)?.map((e) => (
          <Item key={e.label}>
            <ItemButton variant="link" onClick={e.action}>
              <ItemToolIcon
                toolName={e.iconName}
                className={'btn__startIcon' + ' ' + e.toolClass}
                $color={colors.textContrast}
              />
              {e.label}
            </ItemButton>
          </Item>
        ))}
        <Item>
          <ItemButton variant="link" onClick={download}>
            <ItemIcon icon={Download} className="btn__startIcon" />
            Download
          </ItemButton>
        </Item>
      </List>
    )
  }
  return selectedRows?.length ? (
    <TableBoardWrapper>
      {viewport.width < device.tablet && selectedRows?.length === 1 ? (
        <PreviewCloseButton
          className="preview__closeBtn"
          variant="round"
          onClick={clearTableSelection}
          startIcon={close}
        />
      ) : (
        <></>
      )}

      {selectedRows?.length === 1 && (
        <>
          <Preview>
            <FileIcon name={selectedRows[0].name} />
          </Preview>
          <FileInfoBox>
            <FileName>{selectedRows[0].name}</FileName>
            <FileDesc>{getFileType(selectedRows[0].name)}</FileDesc>
          </FileInfoBox>
          <HorizontalLine />
          <ListBox>{getList()}</ListBox>
        </>
      )}
      {selectedRows?.length >= 2 && (
        <>
          <PreviewMultiple>
            <PreviewCustomIcon icon={allPages} />
            <PreviewNumSelected>
              {selectedRows.length} Selected
            </PreviewNumSelected>
            <PreviewCloseButton
              variant="round"
              onClick={clearTableSelection}
              startIcon={close}
            />
          </PreviewMultiple>
          <HorizontalLine />
          <ListBox>{getList()}</ListBox>
        </>
      )}
    </TableBoardWrapper>
  ) : (
    ''
  )
}

export default TableBoard
