import { useState } from 'react'
import styled from 'styled-components'

// import { useTranslation } from '../../hooks/TranslationContext'
import { useForm } from '../../hooks/useForm'
import InputFormRow from '../../controls/InputFormRow'
import Button from '../../controls/Button'
import FormattedText from '../../controls/FormattedText'
import Popup from '../../controls/Popup'
import Icon from '../../controls/Icon'
import MailSentIcon from '../../controls/icons/jobStatusCompleted'
import ButtonRow from '../../controls/ButtonRow'
import { colors } from '../../theme'
import { isEmail } from '../../utils/validators'
// import { gaEvt } from '../../utils/seo'
import { sendDocuments } from '../../service/document/sendDocuments'
// import { logError } from '../../services/logger/logGlobalErrors'
import { useTranslation } from '../../i18n/useTranslation'
import { useLanguage } from '../../i18n/LanguageProvider'
import { sendDocumentByEmailThunk } from '../../store/jobHistory/jobHistoryAsyncThunk'
import { useDispatch } from 'react-redux'

const SubmitCompleted = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  .icon {
    margin-bottom: 1rem;
    width: 60px;
    height: 60px;
  }
  svg {
    width: 60px;
    height: 60px;
    fill: ${colors.successLight};
  }
`

const ErrorSummary = styled.div`
  color: ${colors.error};
  margin-bottom: 1rem;
`

export default function (props) {
  const { user, files, toolName, fileStorageId, onClose } = props
  const t = useTranslation()
  const dispatch = useDispatch()
  const [submitPending, setSubmitPending] = useState(false)
  const [submitCompleted, setSubmitCompleted] = useState(false)
  const [submitFailed, setSubmitFailed] = useState(undefined)
  const { language } = useLanguage()

  const { onChange, onSubmit, values, errors, isValid, isPristine } = useForm({
    initialValues: {
      recipientEmail: '',
      senderEmail: user.email,
      message: '',
    },
    validate: (form) => {
      const formErrors = {}

      if (!form.recipientEmail) {
        formErrors.recipientEmail = t('errEmailRequired')
      } else if (!isEmail(form.recipientEmail)) {
        formErrors.recipientEmail = t('errInvalidEmail')
      }

      if (!form.senderEmail) {
        formErrors.senderEmail = t('errEmailRequired')
      } else if (!isEmail(form.senderEmail)) {
        formErrors.senderEmail = t('errInvalidEmail')
      }

      if (form.message && form.message.length > 1024) {
        formErrors.description = t('errMaxLength1kb')
      }

      return formErrors
    },
    callback: async (form) => {
      setSubmitPending(true)

      try {
        const res = await dispatch(
          sendDocumentByEmailThunk({
            from: form.senderEmail,
            to: form.recipientEmail,
            files,
            templateFields: { message: form.message },
            language,
            toolName,
            fileStorageId,
          })
        ).unwrap()

        // gaEvt('SendDocumentByEmail', toolName, `count: ${files.length}`)
        setSubmitCompleted(true)
      } catch (err) {
        setSubmitFailed(err)
        // logError(err, {
        //   message: 'failed to send documents as email',
        // })
      } finally {
        setSubmitPending(false)
      }
    },
  })
  return (
    <Popup
      onClose={onClose}
      title={t('sendAsEmailForm')}
      footer={() => {
        if (submitCompleted) {
          return (
            <ButtonRow>
              <Button onClick={onClose}>{t('btnCancel')}</Button>
            </ButtonRow>
          )
        }
        return (
          <ButtonRow center noWrap>
            <Button
              loading={submitPending}
              color="primary"
              disabled={submitPending || (!isValid && !isPristine)}
              onClick={onSubmit}
            >
              {t('btnSendEmail')}
            </Button>
            <Button disabled={submitPending} onClick={onClose}>
              {t('btnCancel')}
            </Button>
          </ButtonRow>
        )
      }}
    >
      {submitCompleted ? (
        <SubmitCompleted>
          <Icon icon={MailSentIcon} />
          <FormattedText t={t} resKey="sendAsEmailSuccessful" />
        </SubmitCompleted>
      ) : (
        <form onSubmit={onSubmit}>
          {submitFailed && (
            <ErrorSummary>{t('sendAsEmailSendFailed')}</ErrorSummary>
          )}
          <FormattedText t={t} resKey="sendAsEmailText" />
          <InputFormRow
            label={t('sendAsEmailLblRecipient')}
            errorMessage={isPristine ? undefined : errors.recipientEmail}
          >
            <input
              type="email"
              onChange={onChange}
              name="recipientEmail"
              value={values.recipientEmail}
              placeholder={t('sendAsEmailPlaceholderRecipient')}
              disabled={submitPending}
            />
          </InputFormRow>
          <InputFormRow
            label={t('sendAsEmailLblSender')}
            errorMessage={isPristine ? undefined : errors.senderEmail}
          >
            <input
              type="email"
              placeholder={t('sendAsEmailPlaceholderSender')}
              onChange={onChange}
              name="senderEmail"
              value={values.senderEmail}
              disabled={submitPending}
            />
          </InputFormRow>
          <InputFormRow
            label={t('sendAsEmailLblMessage')}
            errorMessage={isPristine ? undefined : errors.message}
          >
            <textarea
              placeholder={t('sendAsEmailPlaceholderMessage')}
              onChange={onChange}
              name="message"
              value={values.message}
              disabled={submitPending}
            />
          </InputFormRow>
        </form>
      )}
    </Popup>
  )
}
