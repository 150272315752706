import thumbnailsActions from '../../../store/thumbnails/actions'
import { A4PageWidth, A4PageHeight } from './common'

export const calculateSizeSettings = (
  maxContentHeight,
  maxContentWidth,
  thumbnails,
  pageNum
) => {
  const pageLayoutHeight = maxContentHeight
  let maxPageHeight = pageLayoutHeight - 20
  const maxPageWidth = maxContentWidth - 30
  if (maxPageHeight < 350) maxPageHeight = 350
  else if (maxPageHeight > 1000) maxPageHeight = 1000
  const pageHeight = maxPageHeight
  const pageWidth = pageHeight * (A4PageWidth / A4PageHeight)
  let _pageWidth = pageWidth
  let _pageHeight = pageHeight
  let _pageRatio = pageHeight / A4PageHeight
  let landscape = false
  if (thumbnails[pageNum]) {
    landscape = thumbnails[pageNum]
      ? thumbnails[pageNum].pageHeight < thumbnails[pageNum].pageWidth
      : false

    if (landscape) {
      _pageWidth = maxPageWidth
      _pageRatio = _pageWidth / thumbnails[pageNum].pageWidth
      _pageHeight = thumbnails[pageNum].pageHeight * _pageRatio
      if (_pageHeight > maxPageHeight) {
        _pageHeight = maxPageHeight
        _pageRatio = _pageHeight / thumbnails[pageNum].pageHeight
        _pageWidth = thumbnails[pageNum].pageWidth * _pageRatio
        landscape = false
      }
    } else {
      _pageHeight = maxPageHeight
      _pageRatio = _pageHeight / thumbnails[pageNum].pageHeight
      _pageWidth = thumbnails[pageNum].pageWidth * _pageRatio
      if (_pageWidth > maxPageWidth) {
        _pageWidth = maxPageWidth
        _pageRatio = _pageWidth / thumbnails[pageNum].pageWidth
        _pageHeight = thumbnails[pageNum].pageHeight * _pageRatio
        landscape = true
      }
    }
  }

  return {
    _pageWidth,
    _pageHeight,
    _pageRatio,
    maxPageHeight,
    maxPageWidth,
    pageHeight,
    pageWidth,
    landscape,
    pageLayoutHeight,
  }
}

export const PreviousPage = (
  filesReady,
  pageNum,
  pageSelectionType,
  customSelection,
  thumbnails,
  extDocId,
  setPageNum
) => {
  if (filesReady) {
    let prevPage = pageNum
    if (pageSelectionType === 'custom') {
      if (customSelection?.indexOf(pageNum) - 1 >= 0) {
        prevPage = parseInt(
          customSelection[customSelection.indexOf(pageNum) - 1],
          10
        )
      }
    } else if (pageSelectionType === 'all') {
      if (thumbnails[prevPage - 1]) {
        prevPage -= 1
      }
    } else if (!extDocId) {
      return
    }
    setPageNum(prevPage)
  }
}

export const NextPage = (
  filesReady,
  pageNum,
  pageSelectionType,
  customSelection,
  thumbnails,
  extDocId,
  setPageNum
) => {
  if (filesReady) {
    let nextPage = pageNum
    if (pageSelectionType === 'custom') {
      if (customSelection?.indexOf(pageNum) + 1 < customSelection?.length) {
        nextPage = parseInt(
          customSelection[customSelection.indexOf(pageNum) + 1],
          10
        )
      }
    } else if (pageSelectionType === 'all') {
      if (thumbnails[nextPage + 1]) {
        nextPage += 1
      }
    } else if (!extDocId) {
      return
    }
    setPageNum(nextPage)
  }
}

const getThumbnails = async (dispatch, filesReady, inputFiles, isMobile) => {
  if (filesReady) {
    const thumbmnailWidth = isMobile ? 400 : 700
    await dispatch(
      thumbnailsActions.createThumbnails(inputFiles[0], '', thumbmnailWidth)
    )
  }
}

export const pageNavigate = (
  dispatch,
  filesReady,
  inputFiles,
  thumbnails,
  pageNum,
  extDocId,
  pageSelectionType,
  customSelection,
  isMobile
) => {
  let prevDisabled = true
  let nextDisabled = true
  let thumbnailInfo = null
  if (filesReady) {
    if (thumbnails[pageNum]) {
      thumbnailInfo = thumbnails[pageNum]
    } else if (!extDocId) {
      getThumbnails(dispatch, filesReady, inputFiles, isMobile)
    }
    if (pageSelectionType === 'custom') {
      if (customSelection?.indexOf(pageNum) - 1 >= 0) {
        prevDisabled = false
      }
      if (customSelection?.indexOf(pageNum) + 1 < customSelection?.length) {
        nextDisabled = false
      }
    } else if (pageSelectionType === 'all') {
      if (thumbnails[pageNum - 1]) {
        prevDisabled = false
      }
      if (thumbnails[pageNum + 1]) {
        nextDisabled = false
      }
    }
  }

  return {
    thumbnailInfo,
    prevDisabled,
    nextDisabled,
  }
}
