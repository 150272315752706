import React, { useState, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'

import { useDispatch, useSelector } from 'react-redux'
import Popup from '../Popup'
import ButtonRow from '../ButtonRow'
import Button from '../Button'
import { device, media } from '../../theme'
import NeeConsentPopup from '../NeedConsentPopup'
import { updateReadWriteField } from '../../store/teamsAuth/teamsAuthSlice'
import OneDriveFilePicker from '../../components/OneDriveFilePicker'
import { LoadingStates } from '../../utils/enums'

export const Content = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  ${media.down(device.tablet)`
    position: absolute;
    top: 62px;
    bottom: 60px;
    left: 0;
    right: 0;
    height: 74vh;
  `}

  ${media.down(device.mobile)`
    top: 52px;
  `}
`
const SaveToOneDrive = forwardRef((props, ref) => {
  const {
    children,
    showPopup,
    closePopup,
    title,
    btnPrimaryAction,
    btnPrimaryTitle,
    btnPrimaryError,
    loading = false,
  } = props
  const Loading = [LoadingStates.FULFILLED, LoadingStates.REJECTED]
  const isReadWrite = useSelector((state) => state.teamsAuth.isReadWrite)
  const { history, isLoading } = useSelector((state) => state.oneDrive)
  const [isConsentPopup, setIsConsentPopup] = useState(false)
  const dispatch = useDispatch()

  const checkConsent = (callback) => {
    try {
      if (!isReadWrite) {
        setIsConsentPopup(true)
      } else {
        const itemPath = history.reduce(
          (acc, curr) => (curr.name ? acc.concat(curr.name) : acc),
          []
        )
        callback && callback(itemPath)
      }
    } catch (error) {
      setIsConsentPopup(true)
      dispatch(updateReadWriteField({ isReadWrite: false }))
      btnPrimaryError && btnPrimaryError(error)
    }
  }
  const consentPopupClose = () => {
    setIsConsentPopup(true)
  }
  useImperativeHandle(ref, () => ({
    consentPopupClose,
  }))

  return (
    <>
      {isConsentPopup && (
        <NeeConsentPopup onClose={() => setIsConsentPopup(false)} />
      )}
      {showPopup && (
        <Popup
          title={title}
          onClose={closePopup}
          isCustom={true}
          footer={() => {
            return (
              <ButtonRow center noWrap>
                <Button
                  color="primary"
                  id="btnOK"
                  onClick={() => checkConsent(btnPrimaryAction)}
                  loading={!Loading.includes(isLoading)}
                  disabled={!Loading.includes(isLoading)}
                >
                  {btnPrimaryTitle}
                </Button>
                <Button onClick={closePopup}>Cancel</Button>
              </ButtonRow>
            )
          }}
        >
          <Content>
            <OneDriveFilePicker show className="file__picker__pdfeditor" />
          </Content>
        </Popup>
      )}

      {children}
    </>
  )
})

export default SaveToOneDrive
