import React, { useEffect, useState, useCallback } from 'react'
import i18n from '.'

const useTranslation = (moduleName) => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    let isMounted = true

    const loadTranslations = async () => {
      try {
        await i18n.loadTranslations(moduleName)
        if (isMounted) setLoaded(true)
      } catch (error) {
        console.error(
          `Failed to load translations for module: ${moduleName}`,
          error
        )
        if (isMounted) setLoaded(false)
      }
    }

    loadTranslations()

    return () => {
      isMounted = false
    }
  }, [moduleName])

  const t = useCallback(
    (key) => (loaded ? i18n.t(moduleName, key) : `#${moduleName}:${key}#`),
    [loaded, moduleName]
  )

  return t
}

export { useTranslation }
