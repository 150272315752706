import { createAsyncThunk } from '@reduxjs/toolkit'
import { errorHandler } from '../error/action'
import { getJobHistoryOverview } from '../../service/jobHistory/getJobHistoryOverview'
import { sendDocuments } from '../../service/document/sendDocuments'
import { createDocumentLink } from '../../service/document/createDocumentLink'

export const jobHistoryOverviewThunk = createAsyncThunk(
  'jobHistory/fetchJobHistoryOverview',
  async (
    { pageNo = 1, pageSize = 10 },
    { dispatch, getState, rejectWithValue }
  ) => {
    try {
      const req = {
        sorting: {
          orderBy: 'jobCreationDate',
          direction: 'desc',
        },
        paging: {
          page: pageNo,
          pageSize,
        },
        filter: [],
      }
      const res = await getJobHistoryOverview(req)
      return res
    } catch (error) {
      dispatch(errorHandler(error))
      rejectWithValue(error.message)
      return false
    }
  }
)

export const sendDocumentByEmailThunk = createAsyncThunk(
  'jobHistory/sendDocumentByEmail',
  async (
    { from, to, files, templateFields, language, toolName, fileStorageId },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const docBlobRefs = files.map((f) => ({
        blobRefUrl: f.docBlobRef,
        fileName: f.fileName,
        storageType: f.storageType,
      }))
      const sendAsZip = docBlobRefs.length > 1
      const res = await sendDocuments(
        from,
        to,
        files,
        templateFields,
        language,
        toolName,
        fileStorageId,
        docBlobRefs,
        sendAsZip
      )
      return fulfillWithValue(res.status)
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)
export const createDocumentLinkThunk = createAsyncThunk(
  'jobHistory/createDocumentLink',
  async (
    { item, language, toolName },
    { dispatch, getState, rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const docBlobRefs = [item].map((f) => ({
        blobRefUrl: f.docBlobRef,
        fileName: f.name,
        storageType: f.storageType,
      }))
      const docLink = await createDocumentLink(docBlobRefs, language, toolName)
      return fulfillWithValue(docLink)
    } catch (error) {
      dispatch(errorHandler(error))
      return rejectWithValue(error.message)
    }
  }
)
