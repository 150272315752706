export default (props) => {
  const { title, children, className } = props

  const classNames = ['formSection']
  if (className) classNames.push(className)
  return (
    <div className={classNames.join(' ')}>
      {title && <div className="h3">{title}</div>}
      {children}
    </div>
  )
}
