import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import Button from '../../../../controls/Button'
import ButtonRow from '../../../../controls/ButtonRow'
import Checkbox from '../../../../controls/Checkbox'
import FormSection from '../../../../controls/FormSection'
import FormRow from '../../../../controls/FormRow'
import InputFormRow from '../../../../controls/InputFormRow'
import Tab from '../../../../controls/Tab'
import Tabs from '../../../../controls/Tabs'
import Icon from '../../../../controls/Icon'
import okIcon from '../../../../controls/icons/ok'
import errorIcon from '../../../../controls/icons/close'

import { useJobConfig } from '../../../JobConfig/JobContext'
import {
  formatByteSize,
  formatDateTime,
} from '../../../../utils/valueFormatter'

import { colors } from '../../../../theme'
import { useTranslation } from '../../../../i18n/useTranslation'

const IconWrapper = styled.span`
  display: inline-flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  .icon {
    width: 18px;
    height: 16px;
  }
  &.success {
    color: ${colors.successContrast};
    background-color: ${colors.successLight};
  }
  &.failed {
    color: ${colors.errorContrast};
    background-color: ${colors.error};
  }
  &.warning {
    background-color: ${colors.warningLighter};
    border-color: ${colors.warning};
  }
`

const PdfToWordConfig = ({ onChange, form, inputFiles }) => {
  const { updateActionConfig, actionConfig, docIdExt } = useJobConfig()
  const t = useTranslation('tool_pdfMetadata')
  const fileReady = useMemo(
    () => inputFiles[0]?.status === 'finished',
    [inputFiles]
  )
  useEffect(() => {
    if (fileReady) {
      const docMetadata = inputFiles[0]?.docMetadata || {}
      const values = {
        title: '',
        subject: '',
        author: '',
        keywords: '',
        securityType: '',
        application: '',
        producer: '',
        creator: '',
        pdfVersion: '',
        created: '',
        modified: '',
        fileSize: '',
        pages: 0,
        isLinearized: false,
        allowAssembly: false,
        allowCopy: false,
        // allowDegradedPrinting:'',
        allowFillIn: false,
        allowModifyAnnotations: false,
        allowModifyContents: false,
        allowPrint: false,
        allowScreenReaders: false,
        ...docMetadata,
        ...docMetadata?.permissions,
      }
      updateActionConfig(values)
    }
  }, [fileReady])

  return (
    <>
      <FormSection>
        <Tabs>
          <Tab label={t('tabDescription')}>
            <InputFormRow label={t('lblTitle')}>
              <input
                type="text"
                name="title"
                value={form.title}
                onChange={onChange}
              />
            </InputFormRow>
            <InputFormRow label={t('lblAuthor')}>
              <input
                type="text"
                name="author"
                value={form.author}
                onChange={onChange}
              />
            </InputFormRow>
            <InputFormRow label={t('lblPdfProducer')}>
              <input
                type="text"
                name="producer"
                value={form.producer}
                onChange={onChange}
              />
            </InputFormRow>
            <InputFormRow label={t('lblCreator')}>
              <input
                type="text"
                name="creator"
                value={form.creator}
                onChange={onChange}
              />
            </InputFormRow>
            <InputFormRow label={t('lblSubject')}>
              <textarea
                name="subject"
                value={form.subject}
                onChange={onChange}
                rows="2"
              />
            </InputFormRow>
            <InputFormRow label={t('lblKeywords')}>
              <textarea
                name="keywords"
                value={form.keywords}
                onChange={onChange}
              />
            </InputFormRow>
          </Tab>
          <Tab label={t('tabSecurity')}>
            <Checkbox
              name="allowAssembly"
              checked={form.allowAssembly}
              onChange={onChange}
            >
              {t('lblAllowAssembly')}
            </Checkbox>
            <Checkbox
              name="allowCopy"
              checked={form.allowCopy}
              onChange={onChange}
            >
              {t('lblAllowCopy')}
            </Checkbox>
            <Checkbox
              name="allowFillIn"
              checked={form.allowFillIn}
              onChange={onChange}
            >
              {t('lblAllowFillInForms')}
            </Checkbox>
            <Checkbox
              name="allowModifyAnnotations"
              checked={form.allowModifyAnnotations}
              onChange={onChange}
            >
              {t('lblAllowAnnotations')}
            </Checkbox>
            <Checkbox
              name="allowModifyContents"
              checked={form.allowModifyContents}
              onChange={onChange}
            >
              {t('lblAllowModifyContents')}
            </Checkbox>
            <Checkbox
              name="allowPrint"
              checked={form.allowPrint}
              onChange={onChange}
            >
              {t('lblAllowPrint')}
            </Checkbox>
            <Checkbox
              name="allowScreenReaders"
              checked={form.allowScreenReaders}
              onChange={onChange}
            >
              {t('lblAllowScreenReaders')}
            </Checkbox>
          </Tab>
          <Tab label={t('tabAdvanced')}>
            <table className="table">
              <tbody>
                <tr>
                  <td>{t('lblCreator')}</td>
                  <td>{form.creator}</td>
                </tr>
                <tr>
                  <td>{t('lblPdfProducer')}</td>
                  <td>{form.producer}</td>
                </tr>
                <tr>
                  <td>{t('lblPdfVersion')}</td>
                  <td>{form.pdfVersion}</td>
                </tr>
                <tr>
                  <td>{t('lblCreationDate')}</td>
                  <td>{form.creationDate}</td>
                </tr>
                <tr>
                  <td>{t('lblModDate')}</td>
                  <td>{form.modDate}</td>
                </tr>
                <tr>
                  <td>{t('lblFileSize')}</td>
                  <td>{formatByteSize(form.size)}</td>
                </tr>
                <tr>
                  <td>{t('lblPages')}</td>
                  <td>{form.numberOfPages}</td>
                </tr>
                <tr>
                  <td>{t('lblFastWebView')}</td>
                  <td>
                    {form.isLinearized ? (
                      <IconWrapper className="success">
                        <Icon icon={okIcon} />
                      </IconWrapper>
                    ) : (
                      <IconWrapper className="failed">
                        <Icon icon={errorIcon} />
                      </IconWrapper>
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Tab>
        </Tabs>
      </FormSection>
    </>
  )
}

export default PdfToWordConfig
