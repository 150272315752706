import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import ButtonGroup from '../../../../controls/ButtonGroup'
import FormRow from '../../../../controls/FormRow'
import { colors, device, layout, media } from '../../../../theme'
import images from '../../../../assets/images'
import { getMimeTypes } from '../../../../utils/mimeTypes'
import startIcon from '../../../../controls/icons/start'
import uploadIcon from '../../../../controls/icons/upload'
import InputFormRow from '../../../../controls/InputFormRow'
import { useJobConfig } from '../../../JobConfig/JobContext'
import Button from '../../../../controls/Button'
import epubBookIcon from '../../../../controls/icons/epubBook'
import mobiBookIcon from '../../../../controls/icons/mobiBook'
import mobileAndDesktopIcon from '../../../../controls/icons/mobileAndDesktop'
import { useTranslation } from '../../../../i18n/useTranslation'

const CustomForm = styled.div`
  &:hover {
    .changeImage {
      opacity: 0.95;
    }
  }
`

const DropImage = styled.div`
  display: grid;
  justify-content: center;
  // position: absolute;
  text-align: center;
  // z-index: 1;
  // height: 100%;
  // background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  #imageUpload {
    display: none;
  }
  .iconWrapper {
    width: 100%;
    text-align: center;
  }
`

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  #image {
    max-height: 110px;
    box-shadow: ${layout.elevate(5)};
    border: 1px ${colors.gray} solid;
  }
  .changeImage {
    position: absolute;
    z-index: 1;
    align-items: center;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    min-width: 140px;
    #imageUpload {
      display: none;
    }
  }
`

// const CoverBox = styled.div`
//   display: grid;
//   width: 100%;
//   select[name='coverType'] {
//     width: 100%;
//   }
// `
const TextureBoxList = styled.div`
  display: flex;
`
const TextureImage = styled.img`
  height: 40px;
  width: 40px;
  margin: 5px;
  border: 1px ${colors.gray} solid;
  box-shadow: ${layout.elevate(3)};
  cursor: pointer;
`

const DeviceButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  ${media.down(device.mobile)`
    grid-template-columns: 1fr;
  `}
`

let allowDropTimer = null
const PdfToWordConfig = ({ onChange, form, inputFiles }) => {
  const [renderOnce, setRenderOnce] = useState(true)
  const { updateActionConfig } = useJobConfig()
  const t = useTranslation('tool_pdfToEpub')

  useEffect(() => {
    if (
      inputFiles &&
      inputFiles.length &&
      inputFiles[0]?.docMetadata &&
      renderOnce
    ) {
      const values = {
        author:
          inputFiles.length > 0 &&
          inputFiles[0].docMetadata &&
          inputFiles[0].docMetadata.author
            ? inputFiles[0].docMetadata.author
            : '',
        title:
          inputFiles.length > 0 &&
          inputFiles[0].docMetadata &&
          inputFiles[0].docMetadata.title
            ? inputFiles[0].docMetadata.title
            : '',
        firstPageThumbnail:
          inputFiles.length > 0 &&
          inputFiles[0].docMetadata &&
          inputFiles[0].docMetadata.thumbnail
            ? inputFiles[0].docMetadata.thumbnail
            : '',
        device: '',
        outputFormat: 'epub',
        coverType: 'firstPage',
        thumbnail: '',
        thumbnailBlobRef: '',
        texture: textureList[0],
      }

      updateActionConfig(...values)
      setRenderOnce(false)
    }
  }, [inputFiles])
  const dispHeight = ''
  // const deviceList = [
  //   { key: 'default', value: 'Default', fileSupport: 'epub,mobi' },
  //   { key: 'illiad', value: 'iLiad', fileSupport: 'epub,mobi' },
  //   { key: 'ipad', value: 'iPad', fileSupport: 'epub' },
  //   { key: 'jetbook5', value: 'jetBook', fileSupport: 'epub,mobi' }, // old device
  //   { key: 'kindle', value: 'Kindle', fileSupport: 'mobi' },
  //   { key: 'kobo', value: 'Kobo', fileSupport: 'epub,mobi' },
  //   { key: 'msreader', value: 'Microsoft Reader', fileSupport: 'epub,mobi' }, // device discontinued
  //   { key: 'mobipocket', value: 'Mobipocket', fileSupport: 'mobi' }, // device discontinued
  //   { key: 'nook', value: 'NOOK', fileSupport: 'epub' },
  //   { key: 'galaxy', value: 'Samsung Galaxy View', fileSupport: 'epub,mobi' },
  //   { key: 'sony', value: 'SONY', fileSupport: 'epub' },
  //   { key: 'tablet', value: 'Tablet', fileSupport: 'epub,mobi' },
  // ]
  const coverOptions = [
    { key: 'firstPage', value: 'FirstPageCover' },
    { key: 'select', value: 'SelectPredefined' },
    // { key: 'custom', value: 'CustomCover' },
  ]

  const textureList = ['texture1', 'texture2', 'texture3', 'texture4']

  // const [values, setValues] = useState({
  //   author:
  //     inputFiles.length > 0 &&
  //     inputFiles[0].docMetadata &&
  //     inputFiles[0].docMetadata.author
  //       ? inputFiles[0].docMetadata.author
  //       : '',
  //   title:
  //     inputFiles.length > 0 &&
  //     inputFiles[0].docMetadata &&
  //     inputFiles[0].docMetadata.title
  //       ? inputFiles[0].docMetadata.title
  //       : '',
  //   firstPageThumbnail:
  //     inputFiles.length > 0 &&
  //     inputFiles[0].docMetadata &&
  //     inputFiles[0].docMetadata.thumbnail
  //       ? inputFiles[0].docMetadata.thumbnail
  //       : '',
  //   device: '',
  //   outputFormat: 'epub',
  //   coverType: 'firstPage',
  //   thumbnail: '',
  //   thumbnailBlobRef: '',
  //   texture: textureList[0],
  // })

  // const UploadImage = async (imageFile) => {
  //   if (values.coverType !== 'custom') {
  //     return
  //   }
  //   const data = await dispatch(ebookActions.addLocalFile(imageFile))

  //   const fr = new FileReader()
  //   try {
  //     fr.onload = () => {
  //       const _thumbnail = fr.result
  //       // eslint-disable-next-line no-undef
  //       const img = new Image()
  //       img.onload = () => {
  //         setValues({
  //           ...values,
  //           thumbnail: _thumbnail,
  //           thumbnailBlobRef: data[0].docBlobRef,
  //         })
  //       }
  //       img.src = _thumbnail
  //     }
  //     fr.readAsDataURL(imageFile)
  //     // eslint-disable-next-line no-empty
  //   } catch {}
  // }

  // const [localChanges, setLocalChanges] = useState({
  //   isDragActive: false,
  //   acceptedFiles: [],
  // })

  // const onDragover = (e) => {
  //   e.preventDefault()
  //   const { isDragActive } = localChanges

  //   clearTimeout(allowDropTimer)
  //   allowDropTimer = window.setTimeout(() => {
  //     setLocalChanges({
  //       ...localChanges,
  //       isDragActive: false,
  //       acceptedFiles: [],
  //     })
  //   }, 500)
  //   const _acceptedFiles = []

  //   if (!isDragActive) {
  //     if (e.dataTransfer.items) {
  //       for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
  //         const item = e.dataTransfer.items[i]
  //         if (item.kind === 'file') {
  //           if (mimeTypes.includes(item.type)) {
  //             _acceptedFiles.push({
  //               type: item.type,
  //             })
  //           }
  //         }
  //       }
  //     }
  //     setLocalChanges({
  //       ...localChanges,
  //       isDragActive: true,
  //       acceptedFiles: _acceptedFiles,
  //     })
  //   }
  // }

  // const onDrop = (e) => {
  //   const { files } = e.dataTransfer
  //   if (files && files.length > 0) {
  //     for (let i = 0; i < files.length; i += 1) {
  //       const file = files[i]
  //       if (mimeTypes.includes(file.type)) {
  //         UploadImage(file)
  //         break
  //       }
  //     }
  //   }
  // }

  // useEffect(() => {
  //   let _metaData = {
  //     ...inputFiles[0].docMetadata,
  //   }
  //   if (_metaData) {
  //     let _values = { ...values }
  //     if (!_values.title) _values.title = _metaData.title
  //     if (!_values.author) _values.author = _metaData.author
  //     _values.firstPageThumbnail = _metaData.thumbnail

  //     setValues(_values)
  //   }

  //   return () => {}
  // }, [inputFiles, uploadPending])

  // useEffect(() => {
  //   document.body.addEventListener('dragover', onDragover)
  //   document.body.addEventListener('drop', onDrop)

  //   return () => {
  //     document.body.removeEventListener('dragover', onDragover)
  //     document.body.removeEventListener('drop', onDrop)
  //   }
  // }, [values.coverType])

  // let elmInput = null

  // const openFile = () => {
  //   if (elmInput) elmInput.click()
  // }

  // const onFilesSelected = async (e) => {
  //   const imageFile = e.target.files[0]
  //   await UploadImage(imageFile)
  // }

  const { mimeTypes } = getMimeTypes('images')
  return (
    <CustomForm>
      <FormRow label={t('lblEbookFormat')}>
        <ButtonGroup
          name="outputFormat"
          items={[
            {
              id: 'btnOutputEpub',
              value: 'epub',
              label: t('outputEpub'),
              icon: epubBookIcon,
              // disabled: deviceInfo
              //   ? deviceInfo.fileSupport.indexOf('epub') === -1
              //   : false,
            },
            {
              id: 'btnOutputMobi',
              value: 'mobi',
              label: t('outputMobi'),
              icon: mobiBookIcon,
              // disabled: deviceInfo
              //   ? deviceInfo.fileSupport.indexOf('mobi') === -1
              //   : false,
            },
            // {
            //   id: 'btnSelectDevice',
            //   value: 'selectDevice',
            //   label: deviceInfo ? deviceInfo.value : t('lblSelectDevice'),
            //   icon: mobileAndDesktopIcon,
            // },
          ]}
          type="button"
          value={form.outputFormat}
          onChange={onChange}
          // onClick={onOutputFormatClick}
        />
      </FormRow>
      {/* <InputFormRow label={t('lblDevice')}>
            <select
              name="device"
              value={values.device}
              onChange={onDeviceChange}
            >
              {deviceList.map((type) => (
                <option value={type.key} key={type.key}>
                  {type.value}
                </option>
              ))}
            </select>
          </InputFormRow> */}
      <InputFormRow label={t('lblTitle')}>
        <input
          type="text"
          name="title"
          value={form.title}
          onChange={onChange}
        />
      </InputFormRow>
      <InputFormRow label={t('lblAuthor')}>
        <input
          type="text"
          name="author"
          value={form.author}
          onChange={onChange}
        />
      </InputFormRow>

      <InputFormRow label={`${t('lblCover')}`}>
        <select name="coverType" value={form.coverType} onChange={onChange}>
          {coverOptions.map((type) => (
            <option value={type.key} key={type.key}>
              {t(`${type.value}`)}
            </option>
          ))}
        </select>
      </InputFormRow>
      {form.coverType === 'select' && (
        <FormRow>
          <TextureBoxList>
            {textureList.map((tex) => {
              return (
                <div
                  name="texture"
                  onClick={() => {
                    const customValue = { target: {} }
                    customValue.target.name = 'texture'
                    customValue.target.value = tex
                    onChange(customValue)
                    // setSelectTexture(tex)
                  }}
                  onKeyPress={() => {
                    const customValue = { target: {} }
                    customValue.target.name = 'texture'
                    customValue.target.value = tex
                    onChange(customValue)
                    // setSelectTexture(tex)
                  }}
                  role="button"
                  tabIndex="0"
                  key={tex}
                >
                  <TextureImage
                    src={images(`bookcovers/${tex}_small`)}
                    alt={tex}
                  />
                </div>
              )
            })}
          </TextureBoxList>
        </FormRow>
      )}
      <FormRow>
        {form.coverType === 'firstPage' && (
          <ImgWrapper>
            <img
              src={
                form.firstPageThumbnail
                  ? form.firstPageThumbnail
                  : images('blind-page')
              }
              style={{
                height: dispHeight ? `${dispHeight}px` : 'auto',
              }}
              alt="img"
              id="image"
            />
          </ImgWrapper>
        )}
        {/* {values.coverType === 'custom' && (
          <>
            {!form.thumbnailBlobRef ? (
              <DropImage>
                {!imgLoading && (
                  <>
                    <div className="iconWrapper">
                      <Icon icon={uploadIcon} />
                    </div>
                    <span>{t('lblDropImageHere')}</span>
                  </>
                )}
                <Button
                  color="primary"
                  id="btnDropImage"
                  onClick={openFile}
                  loading={imgLoading}
                >
                  {imgLoading ? t('lblUploadingImage') : t('lblSelectHere')}
                </Button>
                <input
                  ref={(elm) => {
                    elmInput = elm
                  }}
                  id="imageUpload"
                  type="file"
                  accept="image/*"
                  onChange={onFilesSelected}
                />
              </DropImage>
            ) : (
              <ImgWrapper>
                <img
                  src={values.thumbnail}
                  style={{
                    height: dispHeight ? `${dispHeight}px` : 'auto',
                    // width: dispWidth ? `${dispWidth}px` : 'auto',
                    // opacity: values.opacity,
                  }}
                  alt="img"
                  id="image"
                />
                <div
                  style={{
                    height: dispHeight ? `${dispHeight}px` : 'auto',
                    // width: dispWidth ? `${dispWidth}px` : 'auto',
                  }}
                  className="changeImage"
                >
                  <Button
                    color="primary"
                    id="btnDropImage"
                    onClick={openFile}
                    loading={imgLoading}
                    size="small"
                  >
                    {t('lblChangeImage')}
                  </Button>
                  <input
                    ref={(elm) => {
                      elmInput = elm
                    }}
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={onFilesSelected}
                  />
                </div>
              </ImgWrapper>
            )}
          </>
        )} */}
        {form.coverType === 'select' && form?.texture && (
          <ImgWrapper>
            <img
              src={images(`bookcovers/${form.texture}`)}
              style={{
                height: dispHeight ? `${dispHeight}px` : 'auto',
              }}
              alt="img"
              id="image"
            />
          </ImgWrapper>
        )}
      </FormRow>
    </CustomForm>
  )
}

export default PdfToWordConfig
