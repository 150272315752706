import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Tab, TabList } from '@fluentui/react-components'
import { useDispatch, useSelector } from 'react-redux'

import ToolTab from '../../components/ToolTab'
import Table from '../../components/Table'

import { withMessageBox } from '../../controls/MessageBox'
import ButtonRow from '../../controls/ButtonRow'
import ParseDocument from '../../controls/icons/parseDocument'
import Upload from '../../controls/icons/upload'
import Icons from '../../assets/Icons'
import Icon from '../../controls/Icon'
import JobConfig from '../../components/JobConfig'
import {
  BannerButton,
  BannerTitle,
  BannerSubTitle,
  Content,
  HomeTabContent,
} from './styles'
import DriveButton from '../../controls/DriveButton'

import '../../theme/scss/base.scss'
import ToggleMenu from '../../components/ToggleMenu'
import TableBoard from '../../components/TableBoard'
import styled, { keyframes } from 'styled-components'
import { media, device, colors } from '../../theme'
import { TeamsFxContext } from '../../components/TeamFxContextProvider'
import Popup from '../../controls/Popup'
import OneDriveFilePicker from '../../components/OneDriveFilePicker'
import { login, logout } from '../../store/auth/action'
import BannerPdfConverter from '../../components/BannerPdfConverter'
import Button from '../../controls/Button'
import { fetchRecentDriveFiles } from '../../store/drive/oneDriveApiSlice'
import { updateUserInfo } from '../../store/teamsAuth/teamsAuthSlice'
import { ButtonRoundCorner } from '../../controls/Common'
import { useTranslation } from '../../i18n/useTranslation'

const TableWrapper = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  margin: 0;
  width: 100%;
`
export const DocumentList_list = styled.div`
  background: var(--body-background);
  border-radius: 5px;
`

const Home = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const { teamsUserCredential } = useContext(TeamsFxContext)
  const t = useTranslation()

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated)
  const { isReadFiles } = useSelector((state) => state.teamsAuth)
  // const { recentDriveFiles } = useSelector((state) => state.recentOneDrive)
  const openPopup = () => {
    setIsPopupOpen(true)
  }
  const closePopup = () => {
    setIsPopupOpen(false)
  }
  const pdf4meOauth = async () => {
    if (!isAuthenticated) {
      dispatch(login())
    } else {
      dispatch(logout())
    }
  }

  const refresh = async () => {
    try {
      const res = await dispatch(fetchRecentDriveFiles(teamsUserCredential))
      if (fetchRecentDriveFiles.rejected.match(res)) {
        await dispatch(updateUserInfo({ isReadFiles: false }))
        // navigate('/need-consent')
      }
    } catch (error) {}
  }

  return (
    <>
      {isPopupOpen && (
        <Popup title={t('lblFileUpload')} onClose={closePopup} isCustom={true}>
          <Content>
            <OneDriveFilePicker
              showUploadBtn
              showFolderBtn
              className="file__picker"
            />
          </Content>
        </Popup>
      )}

      <div className="homeTab">
        <HomeTabContent id="home-tab-content" className="homeTab_content">
          <div className="homeTab_banneer">
            <div className="homeTab_banneer_content">
              <div className="homeTab_banneer_title">
                <BannerTitle className="banner__title">
                  {t('lblWelcomeToPdf4me')}
                </BannerTitle>
                <p className="banner__subtitle">
                  {t('lblPdfDoneSimpleAndOnline')}
                </p>
                {!isAuthenticated && (
                  <ButtonRoundCorner color={'secondary'} onClick={pdf4meOauth}>
                    {t('btnSignIn')}
                  </ButtonRoundCorner>
                )}
              </div>
              <div className="homeTab_actionBlock">
                <div className="banner_thumbnail">
                  <Icons toolName={'logo'} className={'logo_pdf4me'} />
                </div>
                <div className="homeTab_actionBlock_message">
                  <BannerSubTitle
                    className={'banner__title homeTab_actionBlock_tiltle'}
                  >
                    {t('lblConvertToPdf')}
                  </BannerSubTitle>
                  <p className="banner__subtitle homeTab_actionBlock_message">
                    {t('lblSelectAnyDocumentConvertToPdf')}
                  </p>
                  <JobConfig Form={() => <BannerPdfConverter />} />
                </div>
              </div>
            </div>
          </div>
          <div className="home__bottom__wrapper">
            <div className="toolTab_wrapper">
              <ToolTab />
            </div>
            <div className="table_wrapper">
              <TabList defaultSelectedValue={'recent-tab'}>
                <Tab value="recent-tab"> {t('lblRecent')}</Tab>
                <div className="spacer"></div>
                <ButtonRow noWrap>
                  {isReadFiles && (
                    <DriveButton
                      type="button"
                      onClick={refresh}
                      iconName={'refresh'}
                      label={t('btnRefresh')}
                    />
                  )}

                  <DriveButton
                    type="button"
                    onClick={openPopup}
                    iconName={'fileUpload'}
                    label={t('btnUpload')}
                  />
                  <DriveButton
                    type="button"
                    onClick={() => navigate('/document')}
                    iconName={'pdf'}
                    label={t('btnDocument')}
                  />
                </ButtonRow>
              </TabList>
              <JobConfig
                Form={() => (
                  <TableWrapper>
                    <DocumentList_list>
                      <Table ToggleMenu={ToggleMenu} isMultiple={true} />
                    </DocumentList_list>
                    <TableBoard />
                  </TableWrapper>
                )}
              ></JobConfig>
            </div>

            <div className="homeTab_fileContent"></div>
          </div>
        </HomeTabContent>
      </div>
    </>
  )
}

export default withMessageBox(Home)
